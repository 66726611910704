import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { CONTEST_ENTRY_ID_QUERY_PARAM, secondaryColor, sparketBlack } from "utils/constants";
import { useAppDispatch } from "state/hooks.ts";
import { close as closePicksPopout } from "state/slices/my-picks-popout-slice.ts";

interface ViewPoolLinkProps {
  poolId: string;
  contestEntryId?: string;
}

const ViewPoolLink = (props: ViewPoolLinkProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  let poolUrl = `/pools/${props.poolId}`;
  if (props.contestEntryId) {
    poolUrl += `?${CONTEST_ENTRY_ID_QUERY_PARAM}=${props.contestEntryId}`;
  }
  return (
    <Button
      style={{
        padding: 10,
        borderRadius: 2,
        backgroundColor: secondaryColor,
        border: "none",
        color: sparketBlack,
      }}
      onClick={() => {
        dispatch(closePicksPopout());
        navigate(poolUrl);
      }}
    >
      <BlockyHeavyText style={{ color: "black" }}>{"View Pool >"}</BlockyHeavyText>
    </Button>
  );
};

export default ViewPoolLink;
