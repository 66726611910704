import { Contest } from "interfaces/leaderboard/contest";
import { ContestItem } from "components/contests/contest-display-items";
import EnterContestButton from "./enter-contest-button";
import styles from "components/contests/contest-modal/styles.module.css"
import InfoSection from "./info-section/info-secton";
import { useAppSelector } from "state/hooks.ts";
import { isJoined } from "state/slices/contests-slice.ts";
import { Pool } from "interfaces/pool.ts";

interface Props {
  contest: Contest;
  setShowModal: (b: boolean) => void;
  pools: Pool[];
}

const ContestModalDetails = ({contest, setShowModal, pools}: Props) => {

  const joined = useAppSelector((state) => isJoined(state, contest.id));

  return (
    <div className={styles.body}>
      <ContestItem contest={contest} />
      {!joined && <EnterContestButton contest={contest} setShowModal={setShowModal}/>}
      <InfoSection pools={pools} />
    </div>
  )
}

export default ContestModalDetails;