import { InfoCircleFill } from "react-bootstrap-icons";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { textColor } from "utils/constants";

interface Props {
  selectionType: string;
}

const MultipleGroupsTooltip = () => {
  const group = useAppSelector(getGroupState);
  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Popover placement="bottom" id="multiple-groups-tooltip">
          <Popover.Header as={BlockyHeavyText}>
            Don't see your picks?
          </Popover.Header>
          <Popover.Body>
            <BlockyMediumText>
              This page only shows picks from{" "}
              <span style={{ fontWeight: 700 }}>{group.name}</span>. To see your
              other picks please change groups.
            </BlockyMediumText>{" "}
          </Popover.Body>
        </Popover>
      }
    >
      <InfoCircleFill data-testid="info-circle" color={textColor} />
    </OverlayTrigger>
  );
};

export default MultipleGroupsTooltip;
