import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { secondaryColor, sparketBlack } from "utils/constants.ts";
import { Contest } from "interfaces/leaderboard/contest.ts";
import ContestItem from "./contest-item.tsx";
import styles from "assets/shared-stylesheets/sparket-list-item.module.css";
import { useNavigate } from "react-router-dom";

interface ContestItemProps {
  contest: Contest;
}

const ContestItemHome = ({ contest }: ContestItemProps) => {

  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/contest/${contest.id}`, { state: { contest: contest } })
  }

  return (
    <>
      <div
        style={containerStyles}
        onClick={handleClick}
      >
        <div
          className={styles.leftColumnContainer}
          style={leftColumnStyles}
        >
          <ContestItem contest={contest}/>
        </div>
        <div
          className={styles.rightColumnContainer}
          style={{ backgroundColor: secondaryColor}}
        >
          <BlockyHeavyText>{">"}</BlockyHeavyText>
        </div>
      </div>
    </>
  )
};

const containerStyles: any = {
  display: "flex",
  flexDirection: "row",
  marginTop: 10,
  marginBottom: 10,
  marginLeft: 20,
  marginRight: 20,
  backgroundColor: sparketBlack,
  borderRadius: 5,
  boxShadow: "1.4px 2.9px 2.9px hsl(0deg 0% 0% / 0.46)",
  cursor: "pointer",
}

const leftColumnStyles: any = {
  flex: 0.95,
  marginLeft: 0,
  padding: 0,
}

export default ContestItemHome;
