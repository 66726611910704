import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import { DashCircleDotted } from "react-bootstrap-icons";

const InProgressDisplay = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "var(--sparket-gold)",
        fontSize: 12,
      }}
    >
      <DashCircleDotted size={12} /> &nbsp;
      <BlockyBoldText>Entry In Progress</BlockyBoldText>
    </div>
  )
}

export default InProgressDisplay;