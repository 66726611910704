import OpenSansText from "components/custom-texts/open-sans-text";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { BET_STATUS, sparketBlack, sparketLightGray, USER_ID } from "utils/constants";
import BetDetailLine from "./bet-detail-line";
import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import epochToFormattedDateTime from "utils/formatter-utils/date-formatter";
import ViewPoolLink from "./view-pool-link";
import { Bet } from "interfaces/bet";
import StatusBadge from "components/status-badge";
import { projectedPayout } from "utils/projectedPayoutCalculator";
import LoadingSpinner from "components/loading-spinner.tsx";
import styles from "./styles.module.css";
import ShareButtons from "components/bet-details-page/share-buttons";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { useAuth } from "hooks/auth";
import { X } from "react-bootstrap-icons";
import * as storage from "utils/local-storage";
import { getContestById, getContestIdByEntryId } from "state/slices/contests-slice";
import { useEffect, useState } from "react";
import { Pool } from "interfaces/pool.ts";
import { getPoolDetailsPath } from "utils/backend-path-builders.ts";
import { getRequest } from "utils/httpClient.ts";
import { Phase } from "interfaces/leaderboard/phase.ts";
import { Contest } from "interfaces/leaderboard/contest.ts";

const winLossAmount = (
  status: number,
  payout: number,
  amount: number
): string => {
  if (status === BET_STATUS.WIN) {
    return formatAsCurrency(payout);
  } else if (status === BET_STATUS.LOSS) {
    return formatAsCurrency(-amount);
  }
  return "";
};

interface Props {
  bet: Bet;
  loading: boolean;
  hide: () => void;
}

const BetDetailsContent = ({ bet, loading, hide }: Props) => {
  const [pool, setPool] = useState<Pool>();
  const [loadingPool, setLoadingPool] = useState(false);

  const {
    amount,
    odds,
    status,
    pool_id,
    pool_status,
    pool_name,
    pool_close,
    contestant_name,
    payout,
    event_name,
    created,
    event_start,
    ww_listed_amount,
    ww_purchased_amount,
    contest_entry_id,
  } = bet;

  const group = useAppSelector(getGroupState);
  const auth = useAuth();

  const contestId: string = useAppSelector((state) =>
    getContestIdByEntryId(state, contest_entry_id || "")
  );
  const contest = useAppSelector(
    (state) => getContestById(state, contestId)
  );

  useEffect(() => {
    if (group.id) {
      setLoadingPool(true);
      getRequest(getPoolDetailsPath(group.id, pool_id))
        .then(response => setPool(response))
        .finally(() => setLoadingPool(false));
    }
  }, [pool_id, group.id]);

  const resolveContestLineText = (contest: Contest) => {
    if (pool?.phase_id) {

      const contestPhase: Phase | undefined = contest?.phases?.find(p => p.id === pool?.phase_id);
      if (contestPhase) {
        return contest.name + ": " + contestPhase.name;
      }
    }
    return contest.name;
  };

  if (loading || loadingPool) {
    return <LoadingSpinner />;
  } else {
    const isSettled = [BET_STATUS.WIN, BET_STATUS.LOSS].includes(status);
    let pool_status_badge = pool_status;
    let is_bet = false;
    if (status === BET_STATUS.SOLD) {
      pool_status_badge = status;
      is_bet = true;
    }

    const formattedProjectedPayout = formatAsCurrency(
      projectedPayout(amount, odds)
    );
    return (
      <div>
        <div
          style={{
            backgroundColor: sparketBlack,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
          className={styles.topBarContainer}
        >
          <div style={{ position: "absolute", top: 5, right: 5 }}>
            <X onClick={hide} style={{ color: "white", cursor: "pointer" }} size={40} />
          </div>
          <div className={styles.shareBetContainer}>
            <ShareButtons bet={bet} />
          </div>
        </div>
        <div
          style={{ backgroundColor: sparketLightGray }}
          className={`${styles.center} ${styles.reviewBetTextContainer}`}
        >
          <BlockyBoldText customStyles={{ fontSize: 20 }}>
            Pick Review
          </BlockyBoldText>
        </div>
        <div
          style={{
            maxWidth: 700,
            paddingLeft: 10,
            paddingRight: 10,
            margin: "auto",
          }}
        >
          <div className={styles.statusAndPoolLinkContainer}>
            <StatusBadge
              status={pool_status_badge}
              customStyles={{ padding: 10 }}
              is_bet={is_bet}
            />
            <div style={{ marginLeft: "auto" }}>
              <ViewPoolLink poolId={pool_id} contestEntryId={contest_entry_id} />
            </div>
          </div>
          <BetDetailLine label="Group" value={group.name} />
          <BetDetailLine label="Pool" value={event_name + ": " + pool_name} />
          {contest && (<BetDetailLine label="Contest" value={resolveContestLineText(contest)} />)}
          <BetDetailLine
            label="Event Start"
            value={epochToFormattedDateTime(event_start)}
          />
          <BetDetailLine
            label="Pool Close"
            value={epochToFormattedDateTime(pool_close)}
          />
          <BetDetailLine label="Your Pick" value={contestant_name} />
          <BetDetailLine
            label={
              isSettled
                ? "Final Odds"
                : bet.ww_purchased_amount > 0
                  ? "Original Odds"
                  : "Estimated Odds"
            }
            value={odds.toFixed(2) + "X"}
          />
          <BetDetailLine
            label="User id"
            value={
              auth?.user?.attributes?.sub || storage.local.getItem(USER_ID)
            }
          />
          <BetDetailLine label="Pick id" value={bet.id} />
          <BetDetailLine
            label="Pick placed at"
            value={epochToFormattedDateTime(created)}
          />
          <BetDetailLine label="Amount" value={formatAsCurrency(amount)} />
          {bet.ww_purchased_amount > 0 && (
            <BetDetailLine
              label="Purchased Amount"
              value={formatAsCurrency(bet.ww_purchased_amount)}
            />
          )}
          {bet.ww_purchased_amount > 0 && (
            <BetDetailLine
              label="Purchased Odds"
              value={((amount * odds) / ww_purchased_amount).toFixed(2) + "X"}
            />
          )}
          {!isSettled && bet.status !== BET_STATUS.SOLD && (
            <div
              style={{ backgroundColor: sparketLightGray }}
              className={styles.projectedPayoutContainer}
            >
              <OpenSansText customStyles={{ fontSize: 10, opacity: 0.5 }}>
                Projected Return
              </OpenSansText>
              <BlockyBoldText customStyles={{ fontSize: 20, paddingTop: 5 }}>
                {formattedProjectedPayout}
              </BlockyBoldText>
            </div>
          )}
          {bet.status === BET_STATUS.SOLD && (
            <div
              className={styles.winLossSection}
              style={{
                backgroundColor: "#00965E",
              }}
            >
              <BlockyBoldText className={styles.winLossText}>
                Sold Amount {formatAsCurrency(ww_listed_amount)}
              </BlockyBoldText>
            </div>
          )}
          {isSettled && (
            <div
              className={styles.winLossSection}
              style={{
                backgroundColor:
                  status === BET_STATUS.WIN ? "#00965E" : "#CF162E",
              }}
            >
              <BlockyBoldText className={styles.winLossText}>
                {winLossAmount(
                  status,
                  payout,
                  ww_purchased_amount > 0 ? ww_purchased_amount : amount
                )}
              </BlockyBoldText>
            </div>
          )}
        </div>
      </div>
    );
  }
};

export default BetDetailsContent;
