import { Contest, ContestEntry, JoinedContest } from "interfaces/leaderboard/contest";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "state/store";
import { getContestsPath, getJoinedContestsPath, } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";
import { CONTEST_ENTRY_STATUS } from "utils/constants.ts";

export interface ContestsState {
  contests: Contest[];
  joinedContests: JoinedContest[];
  loadingUserContests: boolean;
  loadingContests: boolean;
  error: string | null;
  contestIdToEntryId: Record<string, string[]>;
  entryIdToContestId: Record<string, string>;
  activeContest?: Contest;
  contestsStale: boolean;
  userContestsStale: boolean;
}

export const initialState: ContestsState = {
  contests: [],
  joinedContests: [],
  error: null,
  loadingUserContests: true,
  loadingContests: true,
  contestIdToEntryId: {},
  entryIdToContestId: {},
  activeContest: undefined,
  contestsStale: true,
  userContestsStale: true,
};

export const fetchContests = createAsyncThunk<
  Contest[],
  string,
  { rejectValue: string }
>("contests/fetchContests", async (groupId, { rejectWithValue }) => {
  const contestsPath = getContestsPath(groupId);

  try {
    return await getRequest(contestsPath, { skipIntegrationApi: true });
  } catch (error) {
    console.error(`Error fetching contests`, error);
    return rejectWithValue("Failed to fetch contests");
  }
});

export const fetchUserContests = createAsyncThunk<
  JoinedContest[],
  string,
  { rejectValue: string }
>("contests/fetchUserContests", async (groupId, { rejectWithValue }) => {
  const path = getJoinedContestsPath(groupId);

  try {
    return await getRequest(path, { skipIntegrationApi: true });
  } catch (error) {
    console.error(`Error fetching contests`, error);
    return rejectWithValue("Failed to fetch contests");
  }
});

const slice = createSlice({
  name: "contests",
  initialState,
  reducers: {
    setActiveContest: (state, action) => {
      state.activeContest = action.payload;
    },
    setContestsStale: (state, action) => {
      state.contestsStale = action.payload;
    },
    setUserContestsStale: (state, action) => {
      state.userContestsStale = action.payload;
    },
    clearContestState: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContests.pending, (state) => {
        state.loadingContests = true;
        state.error = null;
      })
      .addCase(fetchContests.fulfilled, (state, action) => {
        state.loadingContests = false;
        state.error = null;

        state.contests = action.payload.slice().sort((a, b) => {
          return new Date(a.end_at).getTime() - new Date(b.end_at).getTime();
        });

        state.activeContest = state.contests.length > 0 ? state.contests[0] : undefined;
      })
      .addCase(fetchContests.rejected, (state, action) => {
        state.loadingContests = false;
        state.error = action.payload as string;
      })
      .addCase(fetchUserContests.pending, (state) => {
        state.error = null;
        state.loadingUserContests = true;
      })
      .addCase(fetchUserContests.fulfilled, (state, action) => {
        action.payload.forEach((joinedContest) => {
          const contestId: string = joinedContest.contest_id;
          const entryIds: string[] = joinedContest.entries.map((entry: ContestEntry) => {
            entry.status = toContestStatus(entry.status);
            return entry.id;
          });

          entryIds.forEach((entryId: string) => {
            addToRecords(state, entryId, contestId);
          });
        });

        state.loadingUserContests = false;
        state.error = null;
        state.joinedContests = action.payload;
      })
      .addCase(fetchUserContests.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loadingUserContests = false;
      });
  },
});

export const {
  setActiveContest,
  setContestsStale,
  setUserContestsStale,
  clearContestState
} = slice.actions;

export const addToRecords = (state: ContestsState, entryId: string, contestId: string) => {
  state.entryIdToContestId[entryId] = contestId;

  if (!state.contestIdToEntryId[contestId]) {
    state.contestIdToEntryId[contestId] = [];
  }

  state.contestIdToEntryId[contestId].push(entryId);
};

const toContestStatus = (
  status: string
): CONTEST_ENTRY_STATUS => {
  if (status in CONTEST_ENTRY_STATUS) {
    return status as CONTEST_ENTRY_STATUS;
  }
  throw new Error(`Invalid status: ${status}`);
};

export const getContestsState = (state: RootState): ContestsState => {
  return state.contests;
};

export const isJoined = (state: RootState, contestId: string) => {
  const joinedContestIds = getContestsState(state).joinedContests.map(
    (c) => c.contest_id
  );

  return joinedContestIds.includes(contestId);
};

export const getContestIdByEntryId = (state: RootState, entryId: string) => {
  return getContestsState(state).entryIdToContestId[entryId];
};

export const getEntryIdsByContestId = (
  state: RootState,
  contestId: string | undefined
): string[] | undefined => {

  return contestId
    ? getContestsState(state).contestIdToEntryId[contestId]
    : undefined;
};

export const getContestById = (state: RootState, contestId: string | undefined) => {
  return getContestsState(state).contests.find((contest) => contest.id === contestId);
};

export const getStatusFromEntryId = (
  state: RootState, contestEntryId: string
): CONTEST_ENTRY_STATUS | undefined => {
  const contestEntry = state.contests.joinedContests
    .flatMap(joinedContest => joinedContest.entries)
    .find(entry => entry.id === contestEntryId);

  return contestEntry?.status;
};

export default slice.reducer;
