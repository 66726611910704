import { Table } from "react-bootstrap";
import { LeaderboardEntry } from "interfaces/leaderboard/leaderboard-entry";
import LeaderTableRow from "components/leaderboard/leader-table/leaderboard-table-row.tsx";
import HeaderRow from "./header-row";
import styles from "./styles.module.css";
import fullScreenStyles from "./full-screen-styles.module.css";
import useDeviceDimensions from "../../../hooks/useDeviceDimensions";
import { sparketBlack, sparketGold } from "utils/constants.ts";

interface Props {
  myEntries: LeaderboardEntry[];
  entries: LeaderboardEntry[];
  fullScreen: boolean;
  disableUserPageLink: boolean;
}

const LeaderTable = ({
                       myEntries,
                       entries,
                       fullScreen,
                       disableUserPageLink
                     }: Props) => {
  const styleSheet = fullScreen ? fullScreenStyles : styles;
  const { isMobile } = useDeviceDimensions();
  return (
    <Table striped>
      <HeaderRow />
      <tbody>
      {!fullScreen && (myEntries.map(myEntry => {
        return <LeaderTableRow
          key={myEntry.user_id + myEntry.contest_entry_id}
          entry={myEntry}
          style={{ backgroundColor: sparketBlack, color: "white" }}
          pendingIconStyle={{ color: sparketGold }}
          classNames={styleSheet}
          isMobile={isMobile}
          disableUserPageLink={disableUserPageLink}
        />;
      }))}

      {entries.map((entry: LeaderboardEntry) => {
        return (
          <LeaderTableRow
            key={entry.user_id + entry.contest_entry_id}
            entry={entry}
            classNames={styleSheet}
            isMobile={isMobile}
            disableUserPageLink={disableUserPageLink}
          />
        );
      })}
      </tbody>
    </Table>
  );
};
export default LeaderTable;
