import ContestInfoContainer from "./contest-info-container.tsx";

interface Props {
  entryFee: number,
}

const EntryFeeDisplay = ({ entryFee }: Props) => {
  const topText = entryFee && entryFee > 0 ? "$" + entryFee : "Free"
  const bottomText = "Entry"

  return (
    <ContestInfoContainer topText={topText} bottomText={bottomText} />
  )
}

export default EntryFeeDisplay;