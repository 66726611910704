import SocialMediaButtons from "./social-media-buttons";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import CopyPoolButton from "./copy-pool-buttton";
import { Bet } from "interfaces/bet";
import { useEffect, useState } from "react";
import { getRequest } from "utils/httpClient";
import { getSocialShareLinksPath } from "utils/backend-path-builders";
import { Spinner } from "react-bootstrap";
import { secondaryColor } from "utils/constants";
import { useAppSelector } from "state/hooks.ts";
import { getGroupState } from "state/slices/group-slice.ts";

interface Props {
  bet: Bet;
}

const Container = ({ bet }: Props) => {
  const group = useAppSelector(getGroupState);

  const { pool_id } = bet;
  const [facebookUrl, setFacebookUrl] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [copyUrl, setCopyUrl] = useState("");
  const [redditUrl, setRedditUrl] = useState("");
  const [whatsappUrl, setWhatsappUrl] = useState("");
  const [loadingUrls, setLoadingUrls] = useState(true);
  useEffect(() => {
    getRequest(getSocialShareLinksPath(pool_id), { skipIntegrationApi: true })
      .then((data) => {
        setFacebookUrl(data.facebook);
        setRedditUrl(data.reddit);
        setTwitterUrl(data.twitter);
        setWhatsappUrl(data.whatsapp);
        setCopyUrl(data.link);
        setLoadingUrls(false);
      })
      .catch((err) => {
        setLoadingUrls(false);
      });
  }, [pool_id, loadingUrls]);

  return (
    <div data-testid="share-buttons">
      <BlockyBoldText
        customStyles={{
          color: secondaryColor,
          fontSize: 20,
          textAlign: "center"
        }}
      >
        Share this {group.isContestGroup ? "contest" : "pool"}!
      </BlockyBoldText>
      {loadingUrls ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            color: secondaryColor,
          }}
        >
          <Spinner animation="border" />
        </div>
      ) : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <CopyPoolButton url={copyUrl} />
          <SocialMediaButtons
            twitter_url={twitterUrl}
            facebook_url={facebookUrl}
            reddit_url={redditUrl}
            whatsapp_url={whatsappUrl}
          />
        </div>
      )}
    </div>
  );
};

export default Container;
