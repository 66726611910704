import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import {
  fetchContests,
  fetchUserContests,
  getContestsState,
  setContestsStale,
  setUserContestsStale
} from "state/slices/contests-slice";
import { useAuth } from "hooks/auth";

const useContestData = () => {
  const dispatch = useAppDispatch();
  const group = useAppSelector(getGroupState);
  const groupId = group.id;
  const {
    contests,
    joinedContests,
    loadingUserContests,
    loadingContests,
    contestsStale,
    userContestsStale,
  } = useAppSelector(getContestsState);
  const auth = useAuth();

  useEffect(() => {
    if (!groupId || !contestsStale || !group.isContestGroup) return;

    dispatch(fetchContests(groupId));
    dispatch(setContestsStale(false));
  }, [dispatch, groupId, contestsStale, group.isContestGroup]);

  useEffect(() => {
    if (!groupId || !auth.signedIn || !userContestsStale || !group.isContestGroup) return;

    dispatch(fetchUserContests(groupId));
    dispatch(setUserContestsStale(false));
  }, [dispatch, groupId, auth.signedIn, userContestsStale, group.isContestGroup]);


  const isContestLoading = auth.signedIn ? loadingContests || loadingUserContests : loadingContests;

  return {
    contests,
    joinedContests,
    loading: group.isContestGroup ? isContestLoading : false,
    refetchUserContests: () => {
      dispatch(setUserContestsStale(true));
      dispatch(setContestsStale(true));
    }
  };
};

export default useContestData;
