import PoolTable from "./pool-table/pool-table";
import styles from "components/contests/contest-modal/styles.module.css"
import { Pool } from "interfaces/pool.ts";


interface Props {
  pools: Pool[];
}

const InfoSection = ({pools}: Props) => {

  return (
    <div style={{marginTop: 10, height: "100%"}}>
      <div className={styles.infoSection}>
        <PoolTable pools={pools}/>
      </div>
    </div>
  )

}

export default InfoSection;