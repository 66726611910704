import Header from "components/header";
import LoginButtons from "components/login-buttons";
import Headroom from "react-headroom";
import React from "react";
import LoadingSpinner from "components/loading-spinner.tsx";
import ContestSelector from "./contest-selector";
import useContestData from "hooks/useContestData";

const ContestHome = () => {
  const { loading } = useContestData();

  return (
    <>
      <Headroom>
        <Header />
        <LoginButtons />
      </Headroom>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {loading
          ? <LoadingSpinner/>
          : <ContestSelector />}
      </div>
    </>
  );
}

export default ContestHome;