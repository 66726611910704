import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import UserGroupsResponse from "interfaces/user-groups-response";
import {
  getGetUserGroupMembershipStatusPath,
  getGroupDetailsPath,
  getJoinGroupPath
} from "utils/backend-path-builders";
import { CURRENT_GROUP_ID, FAILED, IDLE, LOADING, sparketBlack, sparketGold, SUCCEEDED } from "utils/constants";
import { getRequest, isIntegratedApp, postRequest } from "utils/httpClient";
import { RootState } from "state/store";
import { Contest } from "interfaces/leaderboard/contest";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";

const SECTION_SIGN = "§";
export const DOLLAR_SIGN = "$";

export interface GroupState extends UserGroupsResponse {
  status?: UserGroupMembershipStatus;
  fetchStatusState: "idle" | "loading" | "succeeded" | "failed";
  selectedContest?: Contest;
  loading: boolean;
  isContestGroup: false;
}

const INITIAL_PRIMARY_COLOR = "white";
export const initialState: GroupState = {
  updated_at: "",
  created_at: "",
  id: "",
  name: "",
  fee: 0,
  settings: {
    primary_color: INITIAL_PRIMARY_COLOR,
    secondary_color: sparketBlack,
    logo_url: "",
    background_skin: "",
    external_id_label: "external id label",
    contest_terms_url: "",
    group_owner: "",
    contest_label: "",
    enable_geolocation: false,
    allowed_geolocations: [],
    wager_wire: false,
    contest_popup_url:
      "https://lh3.googleusercontent.com/keep-bbsk/AG3SVnCLdnA-dn2FIE5yodse5KFb6Qi7-nmQmGMzWh_9XqBpkbDwu8woDLfRkZuMoQAbX_S3ERp6XCOqQZ0o0O1HEHEzZ0qToACHrBj6F8gx9VN-tg7F=s641",
    enable_contest_popup: true,
  },
  currency_code: "skc",
  fetchStatusState: IDLE,
  uri: "",
  min_age: 21,
  customization: {
    sign_in_up: true,
    bankroll_page: true,
    settings_page: false,
    help_page: true,
    iframe: false,
    confirm_results: true,
    terms_of_service: true,
    leaderboard: true,
    profile_page: true,
    balance: true,
    black_text: true,
    social_sharing_buttons: true,
    contact: true,
  },
  selectedContest: undefined,
  loading: true,
  isContestGroup: false,
};

export const requestToJoinGroup = createAsyncThunk(
  "group/joinRequest",
  async (groupId: string, thunkApi) => {
    if (isIntegratedApp()) {
      return;
    }

    const path = getJoinGroupPath(groupId);
    await postRequest(path, {
      skipIntegrationApi: true,
      queryParams: {
        "referrer-id": localStorage.getItem("referrer-id-" + groupId) || "",
        medium: localStorage.getItem("referrer-medium-" + groupId) || "",
      },
    });
    thunkApi.dispatch(fetchUserGroupMembershipStatus(groupId));
  }
);

export const fetchUserGroupMembershipStatus = createAsyncThunk(
  "group/fetchStatus",
  async (groupId: string, _): Promise<string> => {
    if (isIntegratedApp()) {
      return UserGroupMembershipStatus.APPROVED;
    }

    const path = getGetUserGroupMembershipStatusPath(groupId);
    return await getRequest(path, {
      responseType: "text",
      skipIntegrationApi: true,
    });
  }
);

export const fetchGroupCustomization = createAsyncThunk(
  "group/fetchCustomization",
  async (groupId: string, { dispatch }) => {
    const path = getGroupDetailsPath(groupId);
    try {
      const data = await getRequest(path);
      dispatch(setGroupCustomization(data));
    } catch (error) {
      dispatch(setGroupCustomization(initialState));
    }
  }
);

const slice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroup: (state, action) => {
      if (!action.payload) {
        return state;
      }
      localStorage.setItem(CURRENT_GROUP_ID, action.payload.id);
      state.id = action.payload.id;
      state.updated_at = action.payload.updated_at;
      state.created_at = action.payload.created_at;
      state.name = action.payload.name;
      state.fee = action.payload.fee;
      state.settings = action.payload.settings;
      state.uri = action.payload.uri;
      state.currency_code = action.payload.currency_code;
      state.min_age = action.payload.min_age;
      state.contest_group = action.payload.contest_group;
      state.customization = {
        ...initialState.customization,
        ...action.payload.customization
      };

      // ensures we don't erase defaults if not given some values
      return state;
    },
    setUserGroupMembershipStatus: (state, action) => {
      state.status = action.payload;
      return state;
    },
    setSelectedContest: (state, action) => {
      state.selectedContest = action.payload;
    },
    setGroupCustomization: (state, action) => {
      state.customization = {
        ...initialState.customization,
        ...action.payload.customization
      };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIsContestGroup: (state, action) => {
      state.isContestGroup = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(fetchUserGroupMembershipStatus.pending, (state, action) => {
      state.fetchStatusState = LOADING;
    });
    builder.addCase(fetchUserGroupMembershipStatus.fulfilled, (state, action) => {
      state.status = action.payload as UserGroupMembershipStatus;
      state.fetchStatusState = SUCCEEDED;
    });
    builder.addCase(fetchUserGroupMembershipStatus.rejected, (state, action) => {
      state.fetchStatusState = FAILED;
    });

    builder.addCase(fetchGroupCustomization.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchGroupCustomization.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(fetchGroupCustomization.rejected, (state, action) => {
      state.loading = false;
    });
  },
});

export const {
  setGroup,
  setUserGroupMembershipStatus,
  setGroupCustomization,
  setIsContestGroup,
  setLoading
} = slice.actions;

export const getGroupState = (state: RootState) => {
  return state.group;
};

export const getGroupStyles = (state: RootState) => {
  return getGroupState(state).settings;
};

export const getGroupStatus = (state: RootState) => {
  return getGroupState(state).status;
};

export const getGroupCurrencySymbol = (state: RootState) => {
  const backendCurrencyCode = getGroupState(state).currency_code;

  if (backendCurrencyCode === "usd") {
    return DOLLAR_SIGN;
  } else if (backendCurrencyCode === "skc") {
    return SECTION_SIGN;
  }

  return backendCurrencyCode;
};

export const isRealMoneyGroup = (state: RootState) => {
  return getGroupCurrencySymbol(state) === DOLLAR_SIGN;
};

export const getSlugPath = (state: RootState) => {
  const uri = getGroupState(state).uri;
  return "/g/" + uri;
};

export const getSlugUrl = (state: RootState) => {
  const baseUrl = process.env.REACT_APP_HOST_URL;
  const uri = getGroupState(state).uri;
  return baseUrl + "g/" + uri;
};

export const getGroupSecondaryColorOrDefault = (state: RootState) => {

  if (!getGroupState(state)) {
    return sparketGold;
  }

  return getGroupState(state).settings.secondary_color || sparketGold;
};

export const includeTermsOfService = (state: RootState): boolean => {
  return getGroupState(state).customization.terms_of_service;
};
export default slice.reducer;

export const { setSelectedContest } = slice.actions;
