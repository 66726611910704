import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { ReactComponent as HelpWhite } from "icons/help-white.svg";
import { ReactComponent as HomeWhite } from "icons/home-white.svg";
import { ReactComponent as ConfirmWhite } from "icons/confirm-white.svg";
import { ReactComponent as PiggyBankWhite } from "icons/piggy-bank-white.svg";
import { ReactComponent as MyBetsWhite } from "icons/my-bets-white.svg";
import { ReactComponent as LeaderboardWhite } from "icons/leaderboard-white.svg";
import { ReactComponent as SettingsCog } from "icons/settings-cog.svg";
import { Link, useLocation } from "react-router-dom";
import styles from "./styles.module.css";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { close } from "state/slices/drawer-slice";
import { Mixpanel } from "hooks/mixPanel";
import { useAuth } from "hooks/auth";
import { getGroupStyles } from "state/slices/group-slice";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import { useMemo } from "react";

const ICONS: any = {
  home: HomeWhite,
  bankroll: PiggyBankWhite,
  mybets: MyBetsWhite,
  mycontests: MyBetsWhite,
  leaderboard: LeaderboardWhite,
  help: HelpWhite,
  confirm: ConfirmWhite,
  settings: SettingsCog,
};

interface FooterSectionProps {
  icon: string;
  label: string;
  path: string;
}

const DrawerSection = ({ icon, label, path }: FooterSectionProps) => {
  const auth = useAuth();
  const secondaryColor = useAppSelector(getGroupStyles).secondary_color || "var(--sparket-gold)";
  const currentPath = useLocation().pathname;
  const currentPathBase = currentPath.split("/");
  const isHighlighted = path.split("/")[1] === currentPathBase[1];

  const Icon = ICONS[icon];
  const dispatch = useAppDispatch();

  const color = isHighlighted ? secondaryColor : "white";
  const { isMobile } = useDeviceDimensions();

  const locationState = useMemo(() => {
    if (path === "/leaderboard") {
      if (currentPath.startsWith("/contest/")) {
        const contestId = currentPath.split("/")[2];
        return { contestId };
      }
    }
    return undefined;
  }, [currentPath, path]);

  return (
    <Link
      to={path}
      state={locationState}
      onClick={() => {
        Mixpanel.track("Navigated to " + path + " from drawer", {
          isSignedIn: auth.signedIn,
        });
        if (isMobile) {
          dispatch(close());
        }
      }}
      style={{ textDecoration: "none" }}
    >
      <div className={styles.columnContainer}>
        <div className={styles.iconContainer}>
          <Icon width={50} height={50} fill={color} />
        </div>
        <div className={styles.labelContainer}>
          <BlockyBoldText
            customStyles={{ color }}
            className={`${styles.label}`}
          >
            {label}
          </BlockyBoldText>
        </div>
      </div>
    </Link>
  );
};

export default DrawerSection;
