import { Nav } from "react-bootstrap";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import styles from "./styles.module.css";
import React from "react";

interface Props {
  text: string;
}

const ContestNavItem = ({ text }: Props) => {
  return (
    <Nav.Item >
      <Nav.Link eventKey={text} className={styles.navLink} >
        <BlockyBoldText className={styles.navText}>
          {text}
        </BlockyBoldText>
      </Nav.Link>
    </Nav.Item>
  )
}

export default ContestNavItem;