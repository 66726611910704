const formatMinutes = (date: Date): string => {
  if (date.getMinutes() >= 10) {
    return date.getMinutes().toString();
  }
  return `0${date.getMinutes().toString()}`;
};

const formattedTime = (date: Date): string => {
  const hours = date.getHours();
  if (hours === 0) {
    return `12:${formatMinutes(date)}am`;
  } else if (hours < 12) {
    return `${date.getHours()}:${formatMinutes(date)}am`;
  } else if (hours === 12) {
    return `${date.getHours()}:${formatMinutes(date)}pm`;
  }
  return `${date.getHours() - 12}:${formatMinutes(date)}pm`;
};

const epochToFormattedDateTime = (epoch: number): string => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thu", "Fri", "Sat"];
  const date = new Date(epoch);
  var timeZoneAbbreviation = date
    .toLocaleTimeString("en-us", { timeZoneName: "short" })
    .split(" ")[2];

  return `${daysOfWeek[date.getDay()]} ${
    months[date.getMonth()]
  } ${date.getDate()} ${formattedTime(date)} ${timeZoneAbbreviation}`;
};

export default epochToFormattedDateTime;

export const iso8601DateTimeStringToFormatted = (isoString: string) => {
  const epoch = Date.parse(isoString);
  return epochToFormattedDateTime(epoch);
};

export const substringBeforeExcluding = (string: string, before: string) => string.substr(0, string.indexOf(before));

export const formatDateToYYYYMMDD = (d: Date) => {
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const date = d.getDate().toString().padStart(2, "0");
  const year = d.getFullYear().toString();

  return `${year}-${month}-${date}`;
};

export const epochToContestFormat = (epoch: number): string => {
  const now = new Date();
  const targetDate = new Date(epoch);
  const diffInDays = (targetDate.getTime() - now.getTime()) / (1000 * 60 * 60 * 24);

  let options: Intl.DateTimeFormatOptions = { month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'};
  if (diffInDays <= 7 && targetDate.getDay() > now.getDay()) {
    options = { weekday: 'short', hour: '2-digit', minute: '2-digit'};
  }

  return targetDate.toLocaleDateString('en-US', options)
};
