import React from "react";
import { Image } from "react-bootstrap";
import styles from "./styles.module.css";

interface AvatarAndBadgesProps {
  avatar: string;
  badges: string[];
}

const AvatarAndBadges = ({ avatar, badges }: AvatarAndBadgesProps) => {
  return (
    <div className={styles.displayGrid}>
      <div className={styles.avatar}>
        <Image
          src={avatar}
          className={styles.image}
        />
      </div>
      <div className={styles.badges}>
        {badges.map((badge, index) => (
          <Image
            key={index}
            src={badge}
            className={styles.badge}
          />
        ))}
      </div>
    </div>
  );
};

export default AvatarAndBadges;