import { POOL_STATUS } from "utils/constants";
import { ContestStatusBanner } from "components/contests/contest-display-items/shared/contest-displays";


interface Props {
  status: number;
}

const getStatusInfo = (poolStatus: number) => {
  switch (poolStatus) {
    case POOL_STATUS.OPEN:
      return {
        text: "Open",
        color: "var(--sparket-dark-green",
      };
    case POOL_STATUS.SETTLED:
      return {
        text: "Settled",
        color: "var(--sparket-black)",
      };
    case POOL_STATUS.LIVE:
      return {
        text: "In Progress",
        color: "var(--secondary-color)",
      }
    default:
      return {
        text: "Unknown",
        color: "var(--sparket-black)",
      };
  }
};

const ContestAddition = ({status}: Props) => {
  const poolLabelInfo = getStatusInfo(status);

  return (
    <ContestStatusBanner text={poolLabelInfo.text} color={poolLabelInfo.color}/>
  )
}

export default ContestAddition;