import { JoinedCheck, PendingDisplay } from "./index.ts";
import { useAppSelector } from "state/hooks.ts";
import { getStatusFromEntryId } from "state/slices/contests-slice.ts";
import { CONTEST_ENTRY_STATUS } from "utils/constants.ts";


interface StatusProps {
  contestEntryId: string;
}

const StatusDisplay = ({ contestEntryId }: StatusProps) => {

  const contestStatus = useAppSelector(
    state => getStatusFromEntryId(state, contestEntryId || "")
  );
  return (
    <>
      {contestStatus === CONTEST_ENTRY_STATUS.PENDING
        ? <PendingDisplay />
        : <JoinedCheck />
      }
    </>
  );
};

export default StatusDisplay;