import { Contest } from "interfaces/leaderboard/contest";
import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
import { Phase } from "interfaces/leaderboard/phase";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Option } from "components/leaderboard/leaderboard";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";
import { textColor } from "utils/constants.ts";

interface Props {

  contests: Contest[];

  selectedContest: Contest | undefined;
  setContest: (contest: Contest | undefined) => void;

  selectedPhase: Phase | undefined;
  setPhase: (phase: Phase | undefined) => void;

  disabled: boolean;
}

const ContestAndPhaseSelector = ({
                                   contests,
                                   selectedContest,
                                   setContest,
                                   selectedPhase,
                                   setPhase,
                                   disabled,
                                 }: Props) => {

  if (contests.length === 0) {
    return <div></div>;
  }

  const handleContestSelected = (selectedOptions: Option[]) => {
    setPhase(undefined);

    if (selectedOptions.length === 0) {
      setContest(undefined);
    } else {
      const contest = contests.find((c: Contest) => c.id === selectedOptions[0].id);
      setContest(contest);

      const phases = contest?.phases || [];
      const activePhase = phases.find(phase => phase.is_active);
      if (activePhase) {
        setPhase(activePhase);
      }
    }
  };

  const handlePhaseIdSelected = (phaseId: string | undefined) => {
    if (!phaseId) {
      setPhase(undefined);
    } else {
      if (selectedContest && selectedContest.phases) {
        setPhase((selectedContest.phases).find((p) => p.id === phaseId));
      } else {
        console.error("failed to find selected phase in the contest", phaseId);
      }
    }
  };

  const contestToOption = (contest: Contest) => ({ id: contest.id, label: contest.name } as Record<string, string>);

  return (
    <div style={{ width: "100%", paddingRight: 5, paddingBottom: 5 }}>
      <Typeahead
        id="leaderboard-event-filter"
        options={contests.map(c => contestToOption(c))}
        placeholder="Select Contest"
        disabled={disabled}
        clearButton
        onChange={(contestOptions) => {
          handleContestSelected(
            (contestOptions as Record<string, any>[]).map(option => ({
              id: option.id,
              label: option.label
            }))
          );
        }}
        selected={selectedContest ? [contestToOption(selectedContest)] : []}
        filterBy={() => true}
      />
      {selectedContest && selectedContest.phases?.length &&
        <div style={{ marginTop: 5, display: "flex", flexDirection: "column" }}>
          <Form>
            <Form.Check
              style={{ display: "inline-flex", color: textColor }}
              inline
              type="radio"
              label={<BlockyBoldText style={{ marginLeft: "4px" }}>Overall</BlockyBoldText>}
              name="phases"
              disabled={disabled}
              checked={!selectedPhase}
              id="overall-phase"
              onChange={() => handlePhaseIdSelected(undefined)}
            />
            {(selectedContest.phases ?? []).map((phase: Phase) =>
              <Form.Check
                style={{ display: "inline-flex", color: textColor }}
                key={phase.id}
                inline
                type="radio"
                id={phase.id}
                label={<BlockyBoldText style={{ marginLeft: "4px" }}>{phase.name}</BlockyBoldText>}
                name="phases"
                disabled={disabled}
                checked={selectedPhase?.id === phase.id}
                onChange={(e) => handlePhaseIdSelected(e.target.id)}
              />)}
          </Form>
        </div>}
    </div>
  );
};

export default ContestAndPhaseSelector;
