import { Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "state/hooks";
import { getRequest } from "utils/httpClient";
import { Product, ProductMeta } from "interfaces/commerce/product";
import { BattlePass } from "interfaces/battle-pass";
import { getGroupState } from "state/slices/group-slice";
import { Order } from "interfaces/commerce/order/order";
import BattlePassProductItem from "components/avatar/modal/upgrade-profile/battle-pass-product-item";
import decodeBase64 from "utils/base-64-decoder";
import InovioPurchaseButton from "components/payment-provider/inovio/inovio-purchase-button";

import { hasActive } from "payment-provider/inovio/inovio";
import { isBattlePassActive } from "utils/battle-pass.ts";

interface BattlePassProduct {
  battlePass: BattlePass;
  product: Product;
  orders: Order[];
}

interface UpgradeProfileModalContentProps {
  orders: Order[];
}

const UpgradeProfileModalContent = ({ orders }: UpgradeProfileModalContentProps) => {
  const group = useAppSelector(getGroupState);

  const [selectedProduct, setSelectedProduct] = useState<Product>();

  const [loading, setLoading] = useState<boolean>(false);

  const [products, setProducts] = useState<Product[]>([]);
  const [battlePasses, setBattlePasses] = useState<BattlePass[]>([]);

  const [battlePassProducts, setBattlePassProducts] = useState<BattlePassProduct[]>([]);

  useEffect(() => {

    const battlePassProducts = products.reduce((acc, product) => {
      const battlePass = battlePasses
        .find(battlePass => (product.meta as ProductMeta).battle_pass?.id === battlePass.id);

      if (battlePass) {
        acc.push({
          product: product,
          battlePass: battlePass,
          orders: orders.filter((order) => order.product.id === product.id),
        } as BattlePassProduct);
      }
      return acc;
    }, [] as BattlePassProduct[]);

    setBattlePassProducts(battlePassProducts);
  }, [orders, products, battlePasses]);

  useEffect(() => {
    const fetchData = async () => {
      const [productsResponse, battlePassesResponse] = await Promise.all([
        (await getRequest("/v2/product"))
          .map((p: Product) => ({ ...p, meta: decodeBase64<ProductMeta>(p.meta) })),
        getRequest(`/pass/details/group/${group.id}`),
      ]);

      setProducts(productsResponse);
      setBattlePasses(battlePassesResponse.passes);
    };

    setLoading(true);
    fetchData()
      .catch(e => console.error("error fetching data:", e))
      .finally(() => setLoading(false));
  }, [group.id]);

  useEffect(() => {
    const firstProductWithoutActiveOrder =
      battlePassProducts.find(it => !it.product.purchased && !hasActive(it.orders) && isBattlePassActive(it.battlePass));
    if (firstProductWithoutActiveOrder && !selectedProduct) {
      setSelectedProduct(firstProductWithoutActiveOrder.product);
    }
  }, [selectedProduct, battlePassProducts]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 90,
        }}
      >
        <Spinner animation="border" variant="warning" />
      </div>
    );
  }

  const shownProducts = battlePassProducts
    .filter(({ battlePass, product }) => hasActive(orders) || isBattlePassActive(battlePass) || product.purchased);

  if (shownProducts.length === 0) {
    return (
      <div style={{ color: "white", padding: "1rem" }}>
        No upgrades are currently available.<br />Select ‘Edit Avatar’ to equip purchased upgrades.
      </div>
    );
  }

  const notPurchasedWithoutActiveOrdersProducts = battlePassProducts
    .filter(({ product, orders, battlePass }: BattlePassProduct) =>
      !product.purchased &&
      !hasActive(orders) &&
      isBattlePassActive(battlePass));

  return (
    <div>
      {shownProducts
        .map(({ battlePass, product, orders }) => (
          <BattlePassProductItem
            key={battlePass.id}
            battlePass={battlePass}
            product={product}
            productOrders={orders}
            isSelected={selectedProduct?.id === product?.id}
            onClick={() => {
              if (!hasActive(orders)) {
                setSelectedProduct((prevSelected) => (prevSelected?.id === product!.id ? undefined : product!));
              }
            }} />
        ))}
      {
        notPurchasedWithoutActiveOrdersProducts.length > 0 && <InovioPurchaseButton product={selectedProduct} />
      }
    </div>
  );
};
export default UpgradeProfileModalContent;
