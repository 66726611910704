import { useAppSelector } from "state/hooks";
import { useHandleJoinContest } from "hooks/useHandleJoinContest";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { getGroupStyles } from "state/slices/group-slice";
import { Contest } from "interfaces/leaderboard/contest";
import { LongButton } from "components/contests/contest-display-items/shared/buttons";

interface Props {
  contest: Contest;
  setShowModal: (b: boolean) => void;
}

const EnterContestButton = ({contest, setShowModal}: Props) => {
  const navigate = useNavigate();
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const { handleJoinContest, loading } = useHandleJoinContest();

  const onClick = async () => {
    const contestEntryId = await handleJoinContest(contest.id, setShowModal);
    navigate(`/contest/${contest.id}`, {state: { contestEntryId }});
  }

  return (
    <>
      {loading ?
        <div
          style={{
            display: "flex",
            color: primaryColor,
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
        :
        <LongButton onClick={onClick} text={"Enter"}/>
      }
    </>
  )
}

export default EnterContestButton;