import ContestInfoContainer from "./contest-info-container.tsx";

interface Props {
  maxEntries?: number | undefined,
  totalEntries: number,
}

const MaxEntriesDisplay = ({ maxEntries, totalEntries }: Props) => {
  const topText = maxEntries
    ? `${totalEntries}/${maxEntries}`
    : totalEntries;
  const bottomText = totalEntries === 1 ? "Entry" : "Entries";

  return (
    <ContestInfoContainer topText={topText} bottomText={bottomText} />
  )
}

export default MaxEntriesDisplay;