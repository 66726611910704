import { getPoolsPath } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";
import { Pool } from "interfaces/pool";
import { POOL_STATUS } from "utils/constants";
import { useEffect, useState } from "react";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { toast } from "react-toastify";

interface Params {
  contestId?: string;
}

export const usePoolData = ({ contestId }: Params) => {

  const [pools, setPools] = useState<Pool[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const group = useAppSelector(getGroupState);

  const fetchPools = () => {
    if (!group.id) {
      return;
    }
    let params = {};
    if (contestId) {
      params = { contestId: contestId };
    }
    const path = getPoolsPath(group.id, params);
    getRequest(path, { skipIntegrationApi: true })
      .then((response: Pool[]) => {
        const filteredPools = response.filter((pool) =>
          [POOL_STATUS.OPEN, POOL_STATUS.LIVE, POOL_STATUS.SETTLED].includes(pool.status)
        );

        sortPools(filteredPools);

        setPools(filteredPools);
      })
      .catch(() => toast.error("Something went wrong."))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchPools();
  }, [group.id, contestId]);

  return {
    pools,
    loading
  };
};

const sortPools = (pools: Pool[]) => {
  pools.sort((a, b) => {
    if (a.status !== b.status) {
      return a.status - b.status;
    }

    if (a.weight !== b.weight) {
      return b.weight - a.weight;
    }

    return a.close - b.close;
  });
};