import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import LoadingSpinner from "components/loading-spinner.tsx";
import React, { useState } from "react";
import MyBetItem from "./my-bet-item";
import styles from "./styles.module.css";
import { useBetsData } from "hooks/useBetsData";
import { Bet } from "interfaces/bet.ts";
import BetDetailsModal from "components/my-bets/bet-details-modal.tsx";

interface Props {
  contestEntryId?: string;
}

const MyBets = ({ contestEntryId }: Props) => {

  const { bets, loadingBets } = useBetsData(contestEntryId);

  const [selectedBet, setSelectedBet] = useState<Bet>();

  if (loadingBets || !bets) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {bets.length >= 1 ? bets.map((bet, index) =>
          <MyBetItem
            onClick={b => setSelectedBet(b)}
            key={index}
            bet={bet}
          />)
        :
        <div className={styles.noBetsTextContainer}>
          <BlockyHeavyText>You haven't made any picks yet</BlockyHeavyText>
        </div>
      }

      {selectedBet && <BetDetailsModal bet={selectedBet} onHide={() => setSelectedBet(undefined)} />}
    </>
  );
};

export default MyBets;
