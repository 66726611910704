import { useNavigate } from "react-router-dom";
import { LongButton } from "../../contest-display-items/shared/buttons";

interface Props {
  contestId: string
}

const ViewLeaderboardButton = ({contestId}: Props) => {

  const navigate = useNavigate();
  const onClick = () => {
    navigate(`/leaderboard?contestId=${contestId}`);
  }
  const text = "View Leaderboard"

  return (
    <LongButton onClick={onClick} text={text} />
  )
}

export default ViewLeaderboardButton;