import epochToFormattedDateTime from "utils/formatter-utils/date-formatter";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { Pool } from "interfaces/pool";
import CountdownClock from "./countdown-clock";
import { isPoolClosed } from "utils/pool/is-pool-closed";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { secondaryColor } from "utils/constants";
import ContestAddition from "./contest-addition";

interface PoolItemProps {
  pool: Pool;
  styles: any;
  showCountDownClock: boolean;
  isContestPool?: boolean;
}

const PoolItem = ({ pool, styles, showCountDownClock, isContestPool }: PoolItemProps) => {


  return (
      <div className={styles.itemContainer} style={{ minHeight: 90 }}>
        <div className={styles.leftColumnContainer} style={{ flex: 0.95 }}>
          <BlockyHeavyText className={styles.poolNameText}>
            {pool.event_name && pool.event_name + ": "}
            {pool.name}
          </BlockyHeavyText>
          <div className={styles.closeContainer}>
            <BlockyBoldText className={styles.poolCloseDatePrefix}>
              {isPoolClosed(pool.status) ? "Closed" : "Closes"}:
            </BlockyBoldText>
            <BlockyMediumText style={{ fontSize: 12, display: "inline" }}>
              {epochToFormattedDateTime(pool.close)}
            </BlockyMediumText>
            <BlockyMediumText className={styles.closeDateText}>
              {showCountDownClock && <CountdownClock close={new Date(pool.close)} />}
            </BlockyMediumText>
            {isContestPool && <ContestAddition status={pool.status}/>}
          </div>
        </div>
        <div
          className={styles.rightColumnContainer}
          style={{ backgroundColor: secondaryColor }}
        >
          <BlockyHeavyText>{">"}</BlockyHeavyText>
        </div>
      </div>
  );
};

export default PoolItem;
