import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import useWallet from "hooks/useWallet";

interface Props {
  contestEntryId: string;
}

export const BalanceHeader = ({contestEntryId}: Props) => {

  const {formattedBalance} = useWallet({contestEntryId});

  return (
    <div style={{ margin: 8 }}>
      <BlockyHeavyText
        style={{
          fontSize: 13,
          fontWeight: 700,
          color: "grey"
        }}
      >
        Balance:&nbsp;
      </BlockyHeavyText>
      <BlockyHeavyText
        style={{
          fontWeight: "bold",
          fontSize: 17,
          marginRight: 10,
          color: "white",
        }}
      >
        {formattedBalance}
      </BlockyHeavyText>
    </div>
  )

}

export default BalanceHeader;