import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import { LeaderboardEntry } from "interfaces/leaderboard/leaderboard-entry";
import {
  CONTEST_ENTRY_ID_QUERY_PARAM,
  CONTEST_ENTRY_STATUS,
  DEFAULT_AVATAR_SRC,
  LEADERBOARD_QUERY_PARAM_VALUE,
  REFERRER_QUERY_PARAM
} from "utils/constants";
import styles from "./styles.module.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DashCircleDotted } from "react-bootstrap-icons";

interface Props {
  entry: LeaderboardEntry;
  style?: object;
  pendingIconStyle?: object;
  classNames: any;
  isMobile: boolean;
  disableUserPageLink: boolean;
}

const LeaderboardTableRow = ({ entry, style = {}, pendingIconStyle = {}, isMobile, disableUserPageLink }: Props) => {
  const { rank, avatar, badges, total_earnings, user_id, contestEntryNumber, contestTotalEntryNumbers } = entry;
  const [userName, setUserName] = useState(entry.user_name);

  const avatarSrc = avatar === "" ? DEFAULT_AVATAR_SRC : avatar;

  const badgeGridClass = isMobile ? styles.badgeGridMobile : styles.badgeGridFull;
  const badgeDisplayClass = isMobile ? styles.badgeDisplayMobile : styles.badgeDisplayFull;

  useEffect(() => {
    if (userName.length > 15 && isMobile) {
      setUserName(userName.substring(0, 12) + "...");
    }

    if (!isMobile) {
      setUserName(entry.user_name);
    }
  }, [userName, entry.user_name, isMobile]);

  const resolveUserPageUrl = () => {
    let url = `/user/${user_id}?${REFERRER_QUERY_PARAM}=${LEADERBOARD_QUERY_PARAM_VALUE}`;

    if (entry.contest_entry_id !== null && entry.contest_entry_id !== "") {
      url = url + `&${CONTEST_ENTRY_ID_QUERY_PARAM}=${entry.contest_entry_id}`;
    }

    return url;
  };

  return (
    <tr style={style} key={`${user_id}-${contestEntryNumber}`}>
      <td>
        <div style={{ paddingLeft: 10, display: "flex", alignItems: "center" }}>
          <BlockyHeavyText
            style={{
              marginRight: "5px",
              minWidth: "20px",
              ...style
            }}
          >
            {rank}
          </BlockyHeavyText>

          {entry.contest_entry_status === CONTEST_ENTRY_STATUS.PENDING && (
            <DashCircleDotted style={pendingIconStyle} size={16} />
          )}

          <img
            src={avatarSrc}
            alt="sparket-logo"
            style={{ marginLeft: "auto", marginTop: "-9px", marginBottom: "-9px" }}
            width="38"
            height="38"
          />
        </div>
      </td>
      <td>
        <div style={{ display: "flex", alignItems: "center" }}>
          <BlockyHeavyText style={{
            ...style,
            maxWidth: isMobile ? "120px" : "300px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            verticalAlign: "middle"
          }}>
            {disableUserPageLink ? userName : <Link to={resolveUserPageUrl()}>{userName}</Link>}
          </BlockyHeavyText>
          <div className={badgeGridClass}>
            {badges.map((badge, index) => (
              <img
                key={index}
                src={badge}
                alt="badge"
                className={badgeDisplayClass}
              />
            ))}
          </div>
          {contestTotalEntryNumbers > 1 &&
            <BlockyHeavyText style={{ ...style, marginLeft: 5 }}>#{contestEntryNumber}</BlockyHeavyText>}
        </div>
      </td>
      <td>
        <BlockyHeavyText style={style}>
          {formatAsCurrency(total_earnings)}
        </BlockyHeavyText>
      </td>
    </tr>

  );
};
export default LeaderboardTableRow;
