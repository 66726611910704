import React, { useState } from "react";
import UsernameFormModal from "home/username-form";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { PencilSquare } from "react-bootstrap-icons";


interface UsernameProps {
  username: string;
  readonly?: boolean;
}

const Username = ({ username, readonly = false }: UsernameProps) => {
  const [showChangeUsernameModal, setShowChangeUsernameModal] = useState(false);

  return (
    <div className="d-flex gap-1 justify-content-center align-items-center">
      <BlockyBoldText style={{ color: "white", fontSize: 20 }}>
        {username}
      </BlockyBoldText>

      {!readonly &&
        <div>
          <PencilSquare
            onClick={() => setShowChangeUsernameModal(true)}
            style={{ color: "white", width: 20, height: 20, cursor: "pointer" }}
          />
          <UsernameFormModal
            close={() => setShowChangeUsernameModal(false)}
            show={showChangeUsernameModal}
          />
        </div>
      }
    </div>
  );
};

export default Username;
