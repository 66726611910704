import { getEnterContestPath } from "utils/backend-path-builders";
import { postRequest } from "utils/httpClient";
import { useState } from "react";
import useContestData from "./useContestData";

export const useHandleJoinContest = () => {
  const { refetchUserContests, loading: contestLoading  } = useContestData();
  const [contestEntryId, setContestEntryId] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const handleJoinContest = async (
    contestId: string,
    setShowModal: (b: boolean) => void
  ) => {
    setLoading(true)
    const path = getEnterContestPath();
    const body = {
      body: {
        contest_id: contestId,
        status: "joined",
      },
    };
    try {
      const resp = await postRequest(path, body);
      setContestEntryId(resp.id);
      refetchUserContests();
    } catch (error) {
      console.error("Error joining contest", error);
    }
    setShowModal(false);
    setLoading(false);

    return contestEntryId;
  };

  return {
    handleJoinContest,
    loading: loading || contestLoading,
  }
}