import styles from "./styles.module.css";
import BlockyMediumText from "../../../custom-texts/blocky-medium-text.tsx";
import epochToFormattedDateTime from "utils/formatter-utils/date-formatter.ts";
import BlockyHeavyText from "../../../custom-texts/blocky-heavy-text.tsx";

interface Props {
  endAt: number;
}

const TimeDisplay = ({endAt}: Props) => {
  return (
    <BlockyHeavyText className={styles.timeText}>
      <span style={{ fontSize: 10 }}>Closes: &nbsp;</span>
      <BlockyMediumText className={styles.timeText}>
        {epochToFormattedDateTime(endAt)}
      </BlockyMediumText>
    </BlockyHeavyText>
  )
}

export default TimeDisplay;