import { secondaryColor, sparketBlack } from "utils/constants.ts";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface Props {
  backUrl: string;
}

const BackButton = ({backUrl}: Props) => {
  const navigate = useNavigate();

  const handleSelect = () => {
    navigate(backUrl);
  }

  return (
    <Button
      style={{
        backgroundColor: secondaryColor,
        borderColor: secondaryColor,
        color: sparketBlack,
        borderRadius: 5,
        marginLeft: 20,
      }}
      onClick={handleSelect}
    >
      <BlockyHeavyText customStyles={{ fontSize: 14 }}>
        &lt; Back
      </BlockyHeavyText>
    </Button>
  )
}

export default BackButton;