import Header from "components/header";
import Headroom from "react-headroom";
import MyBets from "./my-bets";
import TitleSection from "./title-section.tsx";
import ContestEntrySelector, {
  ContestEntryOption
} from "components/leaderboard/contest-filter/contest-entry-selector.tsx";
import React, { useEffect, useState } from "react";
import { Contest, JoinedContest } from "interfaces/leaderboard/contest.ts";
import { getRequest } from "utils/httpClient.ts";
import { getContestsPath, getJoinedContestsPath } from "utils/backend-path-builders.ts";
import { toast } from "react-toastify";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus.ts";
import { useAppSelector } from "state/hooks.ts";
import { getGroupState } from "state/slices/group-slice.ts";
import LoadingSpinner from "components/loading-spinner.tsx";
import useQueryParams from "utils/useQueryParams.ts";

const MyPicksPage = () => {
  const group = useAppSelector(getGroupState);
  const queryParams = useQueryParams();
  const contestEntryIdQueryPar: string | null = queryParams.get("contestEntryId");

  const [contestEntries, setContestEntries] = useState<ContestEntryOption[]>();
  const [selectedEntry, setSelectedEntry] = useState<ContestEntryOption | undefined>();
  const userHasJoinedTheGroup = group.status === UserGroupMembershipStatus.APPROVED;

  useEffect(() => {
    const loadContests = async () => {
      try {
        const [contestsResponse, joinedContestsResponse] = await Promise.all([
          getRequest(getContestsPath(group.id), { skipIntegrationApi: true }) as Promise<Contest[]>,
          getRequest(getJoinedContestsPath(group.id), { skipIntegrationApi: true }) as Promise<JoinedContest[]>,
        ]);

        const entries: ContestEntryOption[] = joinedContestsResponse.flatMap((jc: JoinedContest) =>
          jc.entries.map((entry) => ({
            contestEntryId: entry.id,
            contestEntryCreatedAt: entry.created_at,
            contestId: jc.contest_id,
            contestName: contestsResponse.find(c => c.id === jc.contest_id)?.name || "Unknown Contest",
          }))
        );

        if (contestEntryIdQueryPar) {
          const initialEntry = entries.find(entry => entry.contestEntryId === contestEntryIdQueryPar);
          setSelectedEntry(initialEntry);
        }

        setContestEntries(entries);
      } catch (error) {
        toast.error("Something went wrong");
      }
    };

    if (group.id && userHasJoinedTheGroup) {
      if (group.isContestGroup) {
        loadContests();
      } else {
        setContestEntries([])
      }
    }
  }, [group.id, userHasJoinedTheGroup, group.isContestGroup]);

  if (userHasJoinedTheGroup && !contestEntries) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <TitleSection />

      {contestEntries && contestEntries.length > 0 && (
        <div style={{ margin: 20 }}>
          <ContestEntrySelector
            contestEntries={contestEntries}
            setEntry={setSelectedEntry}
            disabled={false}
            selectedEntry={selectedEntry}
          />
        </div>
      )}

      <MyBets contestEntryId={selectedEntry?.contestEntryId} />
    </>
  );
};

export default MyPicksPage;
