import styles from "components/contests/contest-display-items/shared/styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";

interface Props {
  text: string,
  color: string
}

const ContestStatusBanner = ({text, color}: Props) => {
  return (
    <div className={styles.statusContainer}>
      <BlockyBoldText className={styles.statusText}>
        Status:
      </BlockyBoldText>
      <BlockyBoldText
        style={{ color: color }}
        className={styles.statusTypeText}
      >
        {text}
      </BlockyBoldText>
    </div>
  )
}

export default ContestStatusBanner;