import { close, getMyPicksPopoutState } from "state/slices/my-picks-popout-slice";
import { Drawer } from "rsuite";
import MyBets from "components/my-bets/my-bets";
import { useAppDispatch, useAppSelector } from "state/hooks";
import React from "react";
import styles from "components/my-bets/styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import useDeviceDimensions from "hooks/useDeviceDimensions.tsx";

interface Props {
  contestEntryId: string;
}

const MyPicksPopout = ({ contestEntryId }: Props) => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(getMyPicksPopoutState);
  const { isMobile } = useDeviceDimensions();

  return (
    <Drawer
      placement="right"
      backdrop={true}
      open={isOpen}
      onClose={() => dispatch(close())}
      keyboard={false}
      style={{ marginTop: 66, width: isMobile ? "100%" : "calc(100vw - 270)" }}
    >
      <Drawer.Header style={{ padding: 20 }}>
        <div className={styles.titleRow}>
          <BlockyHeavyText customStyles={{ fontSize: 22, marginRight: 10, color: "white" }}>
            My Picks
          </BlockyHeavyText>
        </div>
      </Drawer.Header>
      <Drawer.Body style={{ padding: 20 }}>
        <MyBets contestEntryId={contestEntryId} />
      </Drawer.Body>
    </Drawer>
  );

};

export default MyPicksPopout;