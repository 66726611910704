import { usePoolData } from "hooks/usePoolData";
import LoadingSpinner from "components/loading-spinner.tsx";
import MyContestHeader from "./header";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useState } from "react";
import { getContestById, getEntryIdsByContestId } from "state/slices/contests-slice.ts";
import { useAppSelector } from "state/hooks.ts";
import { PoolList } from "components/pool-item";
import useContestData from "hooks/useContestData.ts";
import { MyPicksPopout } from "components/contests/my-contests";
import ContestModal from "components/contests/contest-modal";
import { useAuth } from "hooks/auth.tsx";

const ContestDetails = () => {
  const [show, setShow] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();

  const { contestEntryId: locationContestEntryId } = location.state || {};
  const { id: contestId } = useParams<{ id: string }>();

  const { loading: contestLoading } = useContestData();
  const { pools, loading: poolLoading } = usePoolData(contestId ? { contestId } : {});

  const contest = useAppSelector((state) => getContestById(state, contestId));
  const entryIds = useAppSelector((state) => getEntryIdsByContestId(state, contestId));
  const contestEntryId = locationContestEntryId || entryIds?.[0];

  const loading = contestLoading || poolLoading;

  if (loading) {
    return <LoadingSpinner />;
  } else if (!contest) {
    navigate("/");
    return;
  }

  const signInHeight = auth.signedIn ? 0 : 59;
  const heightOfTop = 343.5 + signInHeight;

  return (
    <>
      <MyContestHeader
        contest={contest}
        setShowModal={setShow}
        contestEntryId={contestEntryId}
      />
      <div style={{ flexGrow: 1, overflowY: "auto", height: `calc(100vh - ${heightOfTop}px)` }}>
        <PoolList pools={pools} contestEntryId={contestEntryId} contestId={contestId} isContestList={true} />
      </div>

      {contestEntryId && <MyPicksPopout contestEntryId={contestEntryId} />}

      <ContestModal
        contest={contest}
        show={show}
        setShow={setShow}
      />
    </>
  );
};

export default ContestDetails;