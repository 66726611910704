import BlockyBoldText from "../../../../custom-texts/blocky-bold-text.tsx";
import { Button } from "react-bootstrap";
import { secondaryColor } from "utils/constants.ts";


const buttonStyles = {
  background: secondaryColor,
  color: "black",
  border: secondaryColor,
  width: "100%",
};

interface Props {
  onClick: ()=>void;
  text: string;
}

const LongButton = ({ onClick, text }: Props) => {
  return (
    <Button
      style={buttonStyles}
      onClick={onClick}
    >
      <BlockyBoldText>{text}</BlockyBoldText>
    </Button>
  )
}

export default LongButton;