import styles from "./styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { textColor } from "utils/constants.ts";
import MultipleGroupsTooltip from "./multiple-groups-tooltip.tsx";
import React from "react";
import { useAppSelector } from "state/hooks.ts";
import { getAvailableGroups } from "state/slices/available-groups-slice.ts";

const TitleSection = () => {
  const availableGroups = useAppSelector(getAvailableGroups);
  const hasOtherGroups = availableGroups.length > 1;

  return (
    <div className={styles.titleRow}>
      <BlockyHeavyText customStyles={{ fontSize: 22, marginRight: 10, color: textColor }}>
        My Picks
      </BlockyHeavyText>
      {hasOtherGroups && <MultipleGroupsTooltip />}
    </div>
  )
}

export default TitleSection;