import { useAuth } from "hooks/auth";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { secondaryColor, sparketBlack, sparketLightGray, SUCCEEDED } from "utils/constants";
import Header from "components/header";
import SignoutButton from "./signoutButton";
import ProfileInfo from "./profileInfo";
import GroupDetails from "./group-details";
import { Button, Spinner } from "react-bootstrap";
import Headroom from "react-headroom";
import { Mixpanel } from "hooks/mixPanel";
import { useAppSelector } from "state/hooks";
import { getGroupState } from "state/slices/group-slice";
import { getUserState } from "state/slices/user-slice";
import NotificationConfiguration from "components/profile/notification-configuration";
import SparketScore from "./sparket-score/sparketScore";
import { IDENTITY_VERIFICATION_STATUS } from "interfaces/users-response";
import getVerificationStatusIcon from "components/profile/identity-verification-info/getVerificationStatusIcon";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";
import BlockyLightText from "components/custom-texts/blocky-light-text";
import AvatarAndBadgesAndOrders from "components/avatar/avatar-and-badges-and-orders.tsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import OrdersButton from "components/profile/orders/orders-button";
import { Order } from "interfaces/commerce/order/order";
import { getRequest, putRequest } from "utils/httpClient";
import decodeBase64 from "utils/base-64-decoder";
import { ProductMeta } from "interfaces/commerce/product";
import useQueryParams from "utils/useQueryParams";
import { hasActive, XRL_ORDER_ID_INOVIO_QUERY_PARAM } from "payment-provider/inovio/inovio";
import OrderCompletionBanner from "components/profile/orders/order-completion-banner";
import { fetchRewards } from "components/avatar/initialize-rewards";
import { useDispatch } from "react-redux";

interface ProfileSummaryProps {
  email: string;
  orders: Order[];
}

interface ProfileSummaryProps {
  email: string;
  orders: Order[];
}

// Top half of profile
const ProfileSummary = ({ email, orders }: ProfileSummaryProps) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BlockyBoldText style={{ color: secondaryColor, fontSize: 30 }}>
        Profile
      </BlockyBoldText>

      <AvatarAndBadgesAndOrders orders={orders} />

      <OrdersButton orders={orders} />

      <SparketScore />

      <br />

      <BlockyBoldText style={{ color: "white" }}>Signed in as {email}</BlockyBoldText>
    </div>
  );
};
// Bottom half of profile
const ProfileDetails = () => {
  const user = useAppSelector(getUserState);

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <BlockyBoldText>Personal Info</BlockyBoldText>
      <ProfileInfo username={user.user_name} />
      <BlockyBoldText>Email Notification Settings</BlockyBoldText>
      <NotificationConfiguration />
    </div>
  );
};

interface IdentityVerificationButtonProps {
  verificationStatus: IDENTITY_VERIFICATION_STATUS;
}

const IdentityVerificationButton = ({ verificationStatus }: IdentityVerificationButtonProps) => {

  const auth = useAuth();

  const getButtonText = () => {
    switch (verificationStatus) {
      case IDENTITY_VERIFICATION_STATUS.NOT_STARTED:
        return "Verify Identity";
      case IDENTITY_VERIFICATION_STATUS.APPROVED:
        return "Identity Verified";
      case IDENTITY_VERIFICATION_STATUS.REJECTED:
      case IDENTITY_VERIFICATION_STATUS.DUPLICATED:
      case IDENTITY_VERIFICATION_STATUS.MINIMAL_AGE:
        return "Update ID Verification";
      default:
        return "Verify Identity";
    }
  };

  const Icon: JSX.Element | null = getVerificationStatusIcon(verificationStatus);
  const text = getButtonText();

  const verified = verificationStatus === IDENTITY_VERIFICATION_STATUS.APPROVED;

  const buttonText = (
    <BlockyBoldText>
      <div className="d-flex gap-1 justify-content-center align-items-center">
        {Icon &&
          React.cloneElement(Icon, {
            width: 20,
            height: 20
          })}
        {text}
      </div>
    </BlockyBoldText>
  );

  return <Button
    disabled={verified}
    style={{
      opacity: "initial",
      backgroundColor: sparketLightGray,
      color: sparketBlack,
      border: `1px solid ${secondaryColor}`,
      borderRadius: "unset",
      height: 40,
      minWidth: 150,
    }}
  >
    {verified ?
      buttonText :
      <Link
        to="/edit-profile"
        style={{
          textDecoration: "none",
          color: sparketBlack
        }}
        onClick={(e) =>
          Mixpanel.track("Clicked Edit Profile", { isSignedIn: auth.signedIn, })}
      >
        {buttonText}
      </Link>
    }
  </Button>;
};

const getIdentityVerificationHelperTest = (verificationStatus: IDENTITY_VERIFICATION_STATUS) => {

  let message = <></>;

  if ([IDENTITY_VERIFICATION_STATUS.REJECTED,
    IDENTITY_VERIFICATION_STATUS.DUPLICATED,
    IDENTITY_VERIFICATION_STATUS.MINIMAL_AGE].includes(verificationStatus)) {
    message = <BlockyBoldText className={"text-center"}>
      Your ID verification failed.
      Please check your ID and update your information, or contact
      support@betsparket.com.
    </BlockyBoldText>;
  } else if (IDENTITY_VERIFICATION_STATUS.APPROVED === verificationStatus) {
    message = <BlockyLightText className={"text-center p-2"}>
      If you need to update your verified or pending submission, please contact{" "}
      <a href="mailto:support@betsparket.com">support@betsparket.com</a>.
    </BlockyLightText>;
  }

  return <div className={"d-flex justify-content-center p-1"}>
    {message}
  </div>;
};

const Profile = () => {
  const queryParams = useQueryParams();
  const orderId = queryParams.get(XRL_ORDER_ID_INOVIO_QUERY_PARAM);

  const navigate = useNavigate();
  const location = useLocation();

  const auth = useAuth();

  const group = useAppSelector(getGroupState);
  const user = useAppSelector(getUserState);

  const dispatch = useDispatch();
  const [orders, setOrders] = useState<Order[]>([]);

  const pollingIntervalRef = useRef<NodeJS.Timeout>();

  const [newlyCompletedOrders, setNewlyCompletedOrders] = useState<Order[]>([]);

  const fetchOrders = useCallback(() => {
    getRequest("/v2/order")
      .then((orders: Order[]) => {
        if (!orders) {
          console.error("got unexpected orders", orders);
          return;
        }
        const decodedOrders = orders.map(order => ({
          ...order,
          product: {
            ...order.product,
            meta: decodeBase64<ProductMeta>(order.product.meta),
          },
        }));
        setOrders(decodedOrders);
      })
      .catch((e) => console.error("failed to fetch orders", e));
  }, []);

  useEffect(() => {
    if (orderId) {
      putRequest(`/v2/order/${orderId}/paid`)
        .catch(e => console.error("failed to mark order as paid", e))
        .finally(() => navigate(location.pathname));
    }
  }, [navigate, location.pathname, orderId]);

  useEffect(() => {
    const newCompletedOrders = orders.filter((o) => o.status === "COMPLETED" && !o.notified_on_completion);

    setNewlyCompletedOrders(newCompletedOrders);

    newCompletedOrders.forEach((o) => putRequest(`/v2/order/${o.id}/notified-on-completion`));

    fetchRewards(dispatch);
  }, [dispatch, orders]);

  useEffect(() => {
    const hasActiveOrders = hasActive(orders);

    if (hasActiveOrders && !pollingIntervalRef.current) {
      pollingIntervalRef.current = setInterval(() => fetchOrders(), 4000);
    } else if (!hasActiveOrders && pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current);
      pollingIntervalRef.current = undefined;
    }
  }, [orders, fetchOrders]);

  useEffect(() => {
    return () => {
      if (pollingIntervalRef.current) {
        clearInterval(pollingIntervalRef.current);
        pollingIntervalRef.current = undefined;
      }
    };
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  if (auth.pending) {
    return <div>loading...</div>;
  }

  return (
    <div className={"d-flex flex-column"} style={{ minHeight: "100vh" }}>
      <Headroom>
        <Header />
      </Headroom>
      {auth.signedIn ? (
        <>
          <div
            style={{
              backgroundColor: sparketBlack,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
            }}
          >
            {newlyCompletedOrders.length && <OrderCompletionBanner orders={newlyCompletedOrders} />}
            <ProfileSummary email={auth.getEmail()} orders={orders} />
          </div>
          {user.fetchUserState === SUCCEEDED ? <div>
              <div style={{ display: "flex", justifyContent: "center", margin: 10 }}>
                <IdentityVerificationButton verificationStatus={user.validation_status} />
                <SignoutButton />
              </div>
              {getIdentityVerificationHelperTest(user.validation_status)}
              <div className={"mt-5"}>
                <ProfileDetails />
              </div>
            </div> :
            <div className={"d-flex justify-content-center align-content-center align-items-center flex-grow-1"}>
              <Spinner animation="border" variant="warning" />
            </div>
          }

          {group.status === UserGroupMembershipStatus.APPROVED && group.settings.external_id_label && (
            <GroupDetails />
          )}
        </>
      ) : (
        <Navigate to="/" />
      )}
    </div>
  );
};

export default Profile;
