import { Button } from "react-bootstrap";
import { secondaryColor, sparketBlack } from "utils/constants";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import React, { useState } from "react";
import { Product, ProductMeta } from "interfaces/commerce/product";
import { postRequest } from "utils/httpClient";
import { CreateOrderRequest } from "interfaces/commerce/order/create-order-request";
import { Order } from "interfaces/commerce/order/order";
import decodeBase64 from "utils/base-64-decoder";
import { toast } from "react-toastify";

interface InovioPurchaseButtonProps {
  existingOrder?: Order;
  product?: Product;
}

const InovioPurchaseButton: React.FC<InovioPurchaseButtonProps> = ({ existingOrder, product }) => {

  const [redirecting, setRedirecting] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);

  const navigateToPaymentPage = async () => {
    let order: Order;
    if (existingOrder) {
      order = existingOrder;
    } else if (product) {
      setCreatingOrder(true);
      try {
        order = await postRequest("/v2/order",
          { body: { productId: product.id } as CreateOrderRequest, }) as Order;
      } catch (e) {
        console.log("failed to create order");
        toast.error(`Something went wrong.`);
        return;
      } finally {
        setCreatingOrder(false);
      }

      order.product.meta = decodeBase64<ProductMeta>(order.product.meta);

    } else {
      toast.error(`Something went wrong.`);
      console.error("Neither of active order or product properties is provider");
      return;
    }

    setRedirecting(true);

    try {
      window.location.href = order.payment_page_url;
    } catch (e) {
      toast.error(`Something went wrong.`);
      console.error("failed to build inovio hosted payment page url", e);
    }
  };

  return <div className={"d-flex flex-column justify-content-center align-items-center"}>
    <Button
      variant="primary"
      disabled={(!existingOrder && !product) || creatingOrder || redirecting}
      className="mt-2"
      onClick={navigateToPaymentPage}
      style={{
        backgroundColor: secondaryColor,
        color: sparketBlack,
        border: "none",
        borderRadius: "unset",
        height: 40,
        width: 250,
      }}
    >
      <BlockyBoldText>{existingOrder ? "Complete Payment" : "Purchase"}</BlockyBoldText>
    </Button>

    {redirecting &&
      <BlockyBoldText className={"text-center m-2"} style={{ color: "white" }}>
        Navigating you to the payment page...
      </BlockyBoldText>
    }

  </div>;
};

export default InovioPurchaseButton;