import { secondaryColor } from "utils/constants.ts";
import BlockyBoldText from "../../../../custom-texts/blocky-bold-text.tsx";

interface Props {
  name: string;
}

const ContestNameDisplay = ({name}: Props) => {
  return (
    <BlockyBoldText
      style={{
        fontSize: 16,
        display: "flex",
        color: secondaryColor,
      }}
    >
      {name}
    </BlockyBoldText>
  )
}

export default ContestNameDisplay;