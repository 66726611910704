import { Pool } from "interfaces/pool";
import PoolItem from "./pool-item";
import { Link } from "react-router-dom";
import React from "react";

interface Props {
  pool: Pool;
  link: string;
  styles: any;
  showCountDownClock: boolean;
  isContestPool?: boolean;
}

const Wrapper = ({ pool, styles, link, showCountDownClock, isContestPool }: Props) => {

  return (
    <>
      <Link
        id="a-PoolItem-clickPoolItem"
        to={link || ""}
        style={{ textDecoration: "none", color: "unset" }}
      >
        <PoolItem
          key={pool.id}
          styles={styles}
          pool={pool}
          showCountDownClock={showCountDownClock}
          isContestPool={isContestPool}
        />
      </Link>
    </>
  )
}

export default Wrapper;