import Headroom from "react-headroom";
import Header from "components/header";
import {
  CONTEST_ENTRY_ID_QUERY_PARAM,
  DEFAULT_AVATAR_SRC,
  REFERRER_QUERY_PARAM,
  secondaryColor,
  sparketBlack
} from "utils/constants.ts";
import React, { useEffect, useState } from "react";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import Username from "components/profile/username.tsx";
import AvatarAndBadges from "components/avatar/avatar-display/avatar-and-badges.tsx";
import MyBetItem from "components/my-bets/my-bet-item";
import styles from "components/my-bets/styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import { Bet } from "interfaces/bet.ts";
import { useParams } from "react-router-dom";
import { getRequest } from "utils/httpClient.ts";
import { useAppSelector } from "state/hooks.ts";
import { getGroupState } from "state/slices/group-slice.ts";
import { toast } from "react-toastify";
import LoadingSpinner from "components/loading-spinner.tsx";
import useQueryParams from "utils/useQueryParams.ts";
import Spinner from "react-bootstrap/Spinner";
import UsersResponse from "interfaces/users-response.ts";
import { BackButton } from "components/contests/contest-display-items/shared/buttons";

const UserPage = () => {

  const group = useAppSelector(getGroupState);

  const { id: userId } = useParams<{ id: string }>();
  const queryParams = useQueryParams();
  const contestEntryIdQueryParam: string | null = queryParams.get(CONTEST_ENTRY_ID_QUERY_PARAM);
  const referrerQueryParam: string | null = queryParams.get(REFERRER_QUERY_PARAM);

  const [bets, setBets] = useState<Bet[]>();

  const [user, setUser] = useState<UsersResponse>();

  useEffect(() => {
    if (userId) {
      getRequest(`/v2/users/me?userId=${userId}`)
        .then(response => setUser(response))
        .catch(() => toast.error("Something went wrong."));
    }
  }, [userId]);

  useEffect(() => {
    if (userId && group.id) {
      if (contestEntryIdQueryParam) {
        getRequest(`/v2/groups/group/${group.id}/bets?userId=${userId}&contestEntryId=${contestEntryIdQueryParam}`)
          .then((response: Bet[]) => setBets(response))
          .catch(() => toast.error("Failed to load the user's picks."));
      } else {
        setBets([]);
      }
    }
  }, [group.id, userId, contestEntryIdQueryParam]);

  return <div className={"d-flex flex-column"} style={{ minHeight: "100vh" }}>
    <Headroom>
      <Header />
    </Headroom>
    <div
      style={{
        backgroundColor: sparketBlack,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 10,
        position: "relative"
      }}
    >
      <BlockyBoldText style={{ color: secondaryColor, fontSize: 30 }}>
        Profile
      </BlockyBoldText>
      {referrerQueryParam &&
        <div style={{ position: "absolute", left: 0, top: 20 }}>
          <BackButton backUrl={"/" + referrerQueryParam} />
        </div>}

      {user ? <div className={"d-flex flex-column align-items-center"}>
          <Username username={user.user_name} readonly={true} />

          <AvatarAndBadges avatar={user.avatar === "" ? DEFAULT_AVATAR_SRC : user.avatar} badges={user.badges} />
        </div>
        : <div><LoadingSpinner /></div>
      }

    </div>

    {!bets ?
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "40vh"
        }}
      >
        <Spinner animation="border" />
      </div>
      :
      <div>
        {bets?.length > 0 ? (
          <div>
            {bets.map((bet, index) => {
              return (
                <MyBetItem
                  readonly={true}
                  onClick={() => {
                  }}
                  key={index}
                  bet={bet}
                />
              );
            })}
          </div>
        ) : (
          <div className={styles.noBetsTextContainer}>
            <BlockyHeavyText>The user hasn't made any picks yet.</BlockyHeavyText>
          </div>
        )}
      </div>
    }
  </div>;
};

export default UserPage;