import React from "react";
import { Product } from "interfaces/commerce/product";
import { BattlePass } from "interfaces/battle-pass";
import { Check } from "react-bootstrap-icons";
import dateFormatter from "utils/formatter-utils/date-formatter";
import { secondaryColor } from "utils/constants";
import { Order } from "interfaces/commerce/order/order";
import InovioPurchaseButton from "components/payment-provider/inovio/inovio-purchase-button";
import { findOrderByStatus } from "payment-provider/inovio/inovio";
import { Badge } from "react-bootstrap";

interface ProductItemProps {
  battlePass: BattlePass;
  product: Product;
  productOrders: Order[];
  isSelected: boolean;
  onClick: () => void;
}

const BattlePassProductItem: React.FC<ProductItemProps> = ({
                                                             battlePass,
                                                             product,
                                                             isSelected,
                                                             onClick,
                                                             productOrders
                                                           }) => {
  const newOrder = findOrderByStatus(productOrders, "NEW");
  const pendingConfirmationPendingOrder = findOrderByStatus(productOrders, "AWAITING_PAYMENT_CONFIRMATION");
  return (
    <div
      key={battlePass.id}
      onClick={(pendingConfirmationPendingOrder || product.purchased) ? undefined : onClick}
      className="p-2 mb-4"
      style={{
        outline: isSelected ? `3px solid ${secondaryColor}` : "1px solid gray",
        cursor: (pendingConfirmationPendingOrder || product.purchased) ? "not-allowed" : "pointer",
        color: "white",
      }}
    >
      <div className={"row"}>
        <div className="col-10">
          <div><strong>Product:</strong> <span>{product.name}</span></div>
          <div><strong>Description:</strong> <span>{battlePass.description}</span></div>
          <div><strong>Start:</strong> <span>{dateFormatter(battlePass.begin)}</span></div>
          <div><strong>Finish:</strong> <span>{dateFormatter(battlePass.finish)}</span></div>
          <div><strong>Price:</strong> <span>${product.price}</span></div>
          {product.purchased && <Badge bg="success">PURCHASED</Badge>}
        </div>
        {!product.purchased &&
          <div className="col-2 d-flex align-items-start justify-content-end">
            {pendingConfirmationPendingOrder && <Badge bg={"info"} pill={true} style={{
              color: "black",
            }}>PROCESSING PAYMENT</Badge>}

            {isSelected && <Check size={40} color="green" />}
          </div>
        }
      </div>

      {newOrder?.status === "NEW" &&
        <InovioPurchaseButton existingOrder={newOrder} />}
    </div>
  );
};

export default BattlePassProductItem;
