import useDeviceDimensions from "hooks/useDeviceDimensions";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.bs5.css";

interface Props {
  contestEntries: ContestEntryOption[];
  selectedEntry: ContestEntryOption | undefined;
  setEntry: (entry: ContestEntryOption | undefined) => void;
  disabled: boolean;
}

export interface ContestEntryOption {
  contestEntryId: string;
  contestEntryCreatedAt: string;
  contestId: string;
  contestName: string;
}

const ContestEntrySelector = ({
                                contestEntries,
                                selectedEntry,
                                setEntry,
                                disabled,
                              }: Props) => {
  const { isMobile } = useDeviceDimensions();

  const entriesGroupedByContest = contestEntries.reduce<Record<string, ContestEntryOption[]>>((acc, entry) => {
    if (!acc[entry.contestId]) {
      acc[entry.contestId] = [];
    }
    acc[entry.contestId].push(entry);
    return acc;
  }, {});

  const generateLabel = (entry: ContestEntryOption) => {
    const entriesForContest = entriesGroupedByContest[entry.contestId];
    const hasMultipleEntries = entriesForContest.length > 1;
    return hasMultipleEntries
      ? `${entry.contestName} - Entry #${entriesForContest.indexOf(entry) + 1}`
      : entry.contestName;
  };

  const handleContestSelected = (selectedOptions: any[]) => {
    if (selectedOptions.length === 0) {
      setEntry(undefined);
    } else {
      const selected = contestEntries.find(entry => entry.contestEntryId === selectedOptions[0]?.id);
      setEntry(selected);
    }
  };

  const contestToOption = (entry: ContestEntryOption) => ({
    id: entry.contestEntryId,
    label: generateLabel(entry),
  });

  const sortedContestEntries = contestEntries
    .sort((a, b) => Date.parse(b.contestEntryCreatedAt) - Date.parse(a.contestEntryCreatedAt));

  const options = sortedContestEntries.map(contestToOption);

  return (
    <div style={{ width: isMobile ? "100%" : "50%", padding: 5 }}>
      <Typeahead
        id="contest-entry-filter"
        options={options}
        placeholder="Select Contest Entry"
        disabled={disabled}
        clearButton
        onChange={(options) => handleContestSelected(options)}
        selected={selectedEntry ? [contestToOption(selectedEntry)] : []}
        filterBy={() => true}
        labelKey="label"
      />
    </div>
  );
};

export default ContestEntrySelector;
