import { Pool } from "interfaces/pool";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Table } from "react-bootstrap";
import BlockyLightText from "components/custom-texts/blocky-light-text";
import epochToFormattedDateTime from "utils/formatter-utils/date-formatter";
import styles from "components/contests/contest-modal/styles.module.css"
import { sparketBlack } from "utils/constants.ts";

interface Props {
  pools: Pool[];
}

const headerStyle = {
  paddingLeft: 0,
  background: sparketBlack,
  color: "white"
};

const PoolTable = ({ pools }: Props) => {
  return (
    <>
      <Table striped >
        <thead >
          <tr >
            <th style={headerStyle}>
              <BlockyBoldText>
                Pool Name
              </BlockyBoldText>
            </th>
            <th style={headerStyle}>
              <BlockyBoldText>
                Close Time
              </BlockyBoldText>
            </th>
          </tr>
        </thead>
        <tbody>
        {pools.map((pool) => (
          <tr key={pool.id}>
            <td style={{ paddingLeft:0 }}>
              <BlockyLightText className={styles.infoText}>
                {pool.event_name}: {pool.name}
              </BlockyLightText>
            </td>
            <td style={{ paddingLeft:0 }}>
              <BlockyLightText className={styles.infoText}>
                {epochToFormattedDateTime(pool.close)}
              </BlockyLightText>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </>
  );
};

export default PoolTable;
