import { Modal } from "react-bootstrap";
import { Contest } from "interfaces/leaderboard/contest";
import ContestModalDetails from "./contest-modal-details/contest-modal-details.tsx";
import styles from "./styles.module.css";
import { sparketBlack } from "utils/constants.ts";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import { usePoolData } from "hooks/usePoolData.ts";
import Spinner from "react-bootstrap/Spinner";
import { useAppSelector } from "state/hooks.ts";
import { getGroupStyles } from "state/slices/group-slice.ts";

interface Props {
  contest: Contest;
  show: boolean;
  setShow: (show: boolean) => void;
}

const ContestModal = ({ contest, show, setShow }: Props) => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const {loading, pools } = usePoolData( {contestId: contest.id} );

  const hide = () => {
    setShow(false);
  };

  return (
    <Modal show={show} onHide={hide}>
      <Modal.Header style={{ backgroundColor: sparketBlack }} closeVariant={"white"} closeButton>
        <BlockyBoldText
          style={{ color: "white", marginLeft: 10 }}
        >
          Contest Details
        </BlockyBoldText>
      </Modal.Header>
      {loading ?
        <div
          style={{
            color: primaryColor,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner />
        </div> :
        <div className={styles.modal}>
          <ContestModalDetails contest={contest} setShowModal={setShow} pools={pools} />
        </div>
      }
    </Modal>
  );
};

export default ContestModal;