import styles from "../styles.module.css";
import BlockyHeavyText from "../../../../custom-texts/blocky-heavy-text.tsx";

interface Props {
  topText: string | number,
  bottomText: string,
}

const ContestInfoContainer = ({topText, bottomText}: Props) => {
  return (
    <div className={styles.centeredColumn}>
      <BlockyHeavyText className={styles.topContainer}>
        {topText}
      </BlockyHeavyText>
      <BlockyHeavyText className={styles.bottomContainer}>
        {bottomText}
      </BlockyHeavyText>
    </div>
  )
}

export default ContestInfoContainer;