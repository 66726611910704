export const POOL_STATUS = {
  PENDING: 1,
  OPEN: 2,
  LIVE: 3,
  CALCULATING: 4,
  SETTLED: 5,
  CANCELLED: -1,
};

export enum POOL_STATUS_PARAM {
  LIVE = "live",
  OPEN = "open",
}

export const BET_STATUS = {
  CREATED: 1,
  CONFIRMED: 2,
  ACTIVE: 3,
  CANCELLED: 4,
  SOLD: 5,
  WIN: 6,
  LOSS: 7,
};

export const sparketGold = "var(--sparket-gold)";
export const secondaryColor = "var(--secondary-color)";
export const sparketLightGray = "var(--sparket-light-gray)";
export const sparketDarkRed = "var(--sparket-dark-red)";
export const sparketBlack = "#111920";
export const sparketGreen = "#66c085";
export const sparketDarkGreen = "var(--sparket-dark-green";
export const sparketDarkOrange = "var(--sparket-dark-orange";
export const textColor = "var(--text-color)";
export const fiftyYardImgUrl =
  "https://trello-attachments.s3.amazonaws.com/5f3cbc1a9909f162483bcbac/5fdd2990784c2c170712917b/249018ffac7c1867cc04274783a75a00/lights.jpg";
export const logoImgUrl =
  "https://trello-attachments.s3.amazonaws.com/5f3cbc1a9909f162483bcbac/5fdd2990784c2c170712917b/7bcac879b4deeef12bb3605730a89517/socialbetwork.png";
export const HAS_SEEN_SPARKET_TUTORIAL = "hasSeenSparketTutorial";
export const CURRENT_GROUP_ID = "currentGroupId";
export const SUCCEEDED = "succeeded";
export const LOADING = "loading";
export const FAILED = "failed";
export const IDLE = "idle";
export const XPOINT_NOT_FOUND = "XPOINT_NOT_FOUND";
export const SKIP = "SKIP";

export const SMS_MFA = "SMS_MFA";
export const SMS = "SMS";
export const NOMFA = "NOMFA";

export const GRANTED = "granted";

export const EDIT_PROFILE_PATH = "/edit-profile";

export const USERNAME_CUSTOMISATION_POPUP_LIST =
  "usernameCustomisationPopupList";

export const IS_INTEGRATED_APP = "is-integrated-app";
export const USER_ID = "user-id";
export const TOKEN = "token";

export const GROUP_SLUG = "group-slug";

export const USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME = "sparket-main-app-user-settings";

export const CONTEST_TERMS_HEADER = "Contest Terms";

export const CONTEST_ENTRY_ID_QUERY_PARAM = "contestEntryId";
export const REFERRER_QUERY_PARAM = "referrer";
export const LEADERBOARD_QUERY_PARAM_VALUE = "leaderboard";

export enum RewardEnum {
  AVATAR = "Avatar",
  BADGE = "Badge"
}

export interface Reward {
  id: string;
  type: RewardEnum;
  url: string;
  name: string;
  description: string;
  unlocked: boolean;
}

export const DEFAULT_AVATAR_SRC: string = "https://battle-pass-rewards.s3.us-west-2.amazonaws.com/avatars/SPRK-BATTLEPASS-062524_SPARKET-GRAY.svg";

export const DEFAULT_AVATAR_REWARD: Reward = {
  // TODO: add reward id
  id: "",
  type: RewardEnum.AVATAR,
  url: DEFAULT_AVATAR_SRC,
  name: "SPARKET GRAY",
  description: "Unlock by signing up for SPARKET!",
  unlocked: true,
};

export const DEFAULT_AVATAR = {
  [RewardEnum.AVATAR]: [DEFAULT_AVATAR_SRC],
  [RewardEnum.BADGE]: [],
};

export const BADGE_GRID_DIMENSIONS = { ROWS: 4, COLUMNS: 3 };

export const SPORTZINO_SYMBOLS = ["GC", "SC"];

export const WJAL_REMOTE_GROUP_ID = "70a2a58e-e7ef-453b-b684-3af4fd153b09";

export enum CONTEST_ENTRY_STATUS {
  VALID = "VALID",
  PENDING = "PENDING",
}

export enum CONTEST_STATE {
  PENDING = "pending",
  OPEN = "open",
  LIVE = "live",
  SETTLED = "settled",
}