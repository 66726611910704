import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "state/store";

interface MyPicksPopoutSlice {
  isOpen: boolean;
}

const initialState: MyPicksPopoutSlice = { isOpen: false };

const slice = createSlice({
  name: "myPicksPopout",
  initialState,
  reducers: {
    toggle: (state) => {
      state.isOpen = !state.isOpen;
    },
    close: (state) => {
      state.isOpen = false;
    },
    open: (state) => {
      state.isOpen = true;
    },
  },
});

export const { toggle, close, open } = slice.actions;

export const getMyPicksPopoutState = (state: RootState) => {
  return state.myPicksPopout.isOpen;
};

export default slice.reducer;
