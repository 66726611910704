import { Modal } from "react-bootstrap";
import BetDetailsContent from "components/bet-details-page/bet-details-content";
import { Bet } from "interfaces/bet";

interface Props {
  bet: Bet | undefined;
  onHide: () => void;
}

const BetDetailsModal = ({ bet, onHide }: Props) => {
  return (
    // Experiment here is enforceFocus. I want to see if it fixes the auto scrolling
    // behavior in an iframe.
    <Modal enforceFocus={false} onHide={onHide} show={!!bet} style={{ zIndex: 999999999999 }}>
      <div
        style={{
          paddingBottom: 10,
          background: "white",

        }}
      >
        {bet && <BetDetailsContent hide={onHide} bet={bet} loading={false} />}
      </div>
    </Modal>
  );
};

export default BetDetailsModal;
