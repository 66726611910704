import { getRequest } from "utils/httpClient";
import { Fullscreen, GiftFill, Trophy } from "react-bootstrap-icons";
import { Button } from "react-bootstrap";
import { LeaderboardResponse } from "interfaces/leaderboard/leaderboard-response";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "components/loading-spinner.tsx";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState, getGroupStyles, setSelectedContest } from "state/slices/group-slice";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import FullscreenHeader from "./full-screen-header";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import LeaderTable from "./leader-table";
import CallToActionFooter from "./full-screen-footer/call-to-action-footer";
import { openContestTerms } from "state/slices/legal-modal-slice";
import ContestAndPhaseSelector from "./contest-filter";
import { Contest, JoinedContest } from "interfaces/leaderboard/contest";
import { getContestsPath, getJoinedContestsPath, getLeaderboardDataPath } from "utils/backend-path-builders";
import { Phase } from "interfaces/leaderboard/phase";
import { CONTEST_STATE, secondaryColor, textColor } from "utils/constants";
import { Mixpanel } from "hooks/mixPanel";
import { useLocation, useNavigate } from "react-router-dom";
import { LeaderboardEntry } from "interfaces/leaderboard/leaderboard-entry.ts";
import useQueryParams from "utils/useQueryParams.ts";
import { toast } from "react-toastify";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus.ts";
import { useAuth } from "hooks/auth.tsx";


export interface Option {
  id: string;
  label: string;
}

const Leaderboard = () => {

  const location = useLocation();

  const auth = useAuth();
  const handle = useFullScreenHandle();
  const fullScreenActive = handle.active;
  const enterFullScreen = handle.enter;
  const { isMobile } = useDeviceDimensions();

  const queryParams = useQueryParams();

  const contestIdQueryPar = queryParams.get("contestId");

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const group = useAppSelector(getGroupState);
  const groupId = group.id;

  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const [contestsFilterInitialized, setContestsFilterInitialized] = useState(false);

  const [groupContests, setGroupContests] = useState<Contest[]>();

  const [joinedContests, setJoinedContests] = useState<JoinedContest[]>();

  const [availableContests, setAvailableContests] = useState<Contest[]>([]);


  const [contestFilter, setContestFilter] = useState<Contest>();
  const [phaseFilter, setPhaseFilter] = useState<Phase>();

  const [leaderboardResponse, setLeaderboardResponse] = useState<LeaderboardResponse>();
  const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);

  const initializeContestFilter = (contests: Contest[]) => {

    let initialContest: Contest | undefined;

    if (contestIdQueryPar) {
      initialContest = contests.find(c => c.id === contestIdQueryPar);
    }

    const navigatedFromContestId = location.state?.contestId;
    if (navigatedFromContestId) {
      initialContest = contests.find(c => c.id === navigatedFromContestId);
    }

    if (!initialContest) {
      initialContest = contests.find(c => c.is_active);
    }

    if (!initialContest) {
      initialContest = contests.find(c => c.state === CONTEST_STATE.LIVE || c.state === CONTEST_STATE.OPEN);
    }

    if (initialContest) {
      setContestFilter(initialContest);
      dispatch(setSelectedContest(initialContest));

      const activePhase = initialContest.phases?.find(phase => phase.is_active);
      setPhaseFilter(activePhase);
    }

    setContestsFilterInitialized(true);
  };
  const userHasJoinedTheGroup = group.status && group.status === UserGroupMembershipStatus.APPROVED;

  // fetch joined contests if contest group
  useEffect(() => {
    if (group.id && group.isContestGroup && userHasJoinedTheGroup && auth.signedIn) {
      getRequest(getJoinedContestsPath(groupId), { skipIntegrationApi: true })
        .then(joinedContestsResponse => setJoinedContests(joinedContestsResponse))
        .catch(() => toast.error("Something went wrong."));
    }
  }, [group.id, group.isContestGroup, userHasJoinedTheGroup, auth]);

  // fetch contests
  useEffect(() => {
    if (group.id) {
      getRequest(getContestsPath(groupId), { skipIntegrationApi: true })
        .then((contestsResponse: Contest[]) => setGroupContests(contestsResponse.filter(c => !c.is_deleted)))
        .catch(() => toast.error("Something went wrong."));
    }
  }, [group.id]);


  const findActiveContest = (contests: Contest[]) => contests.find(c => c.is_active);

  useEffect(() => {
    const resolveAvailableContestsAndInitializeContestFilter = () => {
      if (!group.id || !groupContests) return;

      const getAvailableContests = (): Contest[] | undefined => {
        const activeContest = findActiveContest(groupContests);

        if (group.isContestGroup) {
          if (!auth.signedIn) return activeContest ? [activeContest] : [];
          if (!userHasJoinedTheGroup) return activeContest ? [activeContest] : [];

          if (joinedContests) {
            const joinedContestIds = joinedContests.map(jc => jc.contest_id);
            const filteredJoinedContests = groupContests.filter(c => joinedContestIds.includes(c.id));
            const contestsByMostRecentJoined = sortContestsByMostRecentJoined(filteredJoinedContests, joinedContests);

            if (activeContest && !contestsByMostRecentJoined.some(c => c.id === activeContest.id)) {
              contestsByMostRecentJoined.unshift(activeContest);
            }
            return contestsByMostRecentJoined;
          }
          return undefined; // Wait until joined contests are loaded
        }

        return groupContests;
      };

      const result = getAvailableContests();

      if (result) {
        setAvailableContests(result);
        initializeContestFilter(result);
      }
    };

    resolveAvailableContestsAndInitializeContestFilter();
  }, [group.id, groupContests, joinedContests, group.isContestGroup, userHasJoinedTheGroup, auth.signedIn]);

  useEffect(() => {
    const fetchLeaderboard = () => {
      setLoadingLeaderboard(true);
      getRequest(getLeaderboardDataPath(), {
        skipIntegrationApi: true,
        queryParams: {
          currency: group.currency_code || "skc",
          page_size: 100,
          "group-id": group.id,
          ...(contestFilter?.id && { "contest-id": contestFilter?.id }),
          ...(phaseFilter?.id && { "phase-id": phaseFilter?.id }),
        },
      })
        .then((response: LeaderboardResponse) => {
          const assignEntryNumbers = (entries: LeaderboardEntry[]) => {
            const entriesByUserId = entries.reduce((acc, entry) => {
              const userId = entry.user_id;
              if (!acc[userId]) acc[userId] = [];
              acc[userId].push(entry);
              return acc;
            }, {} as { [key: string]: LeaderboardEntry[] });

            return Object.values(entriesByUserId)
              .map(userEntries => {
                return userEntries
                  .sort((a, b) => new Date(a.contest_entry_created_at!).getTime() - new Date(b.contest_entry_created_at!).getTime())
                  .map((entry, index) => ({
                    ...entry,
                    contestEntryNumber: index + 1,
                    contestTotalEntryNumbers: userEntries.length
                  }));
              })
              .flat();
          };

          const userListWithEntryNumbers = assignEntryNumbers(response.user_list ?? []);
          const myEntriesWithEntryNumbers = assignEntryNumbers(response.user_entries ?? []);

          const sortedUserList = userListWithEntryNumbers.sort((a, b) => a.rank - b.rank);
          const sortedMyEntries = myEntriesWithEntryNumbers.sort((a, b) => a.rank - b.rank);

          setLeaderboardResponse({
            ...response,
            user_list: sortedUserList,
            user_entries: sortedMyEntries,
          });
        })
        .catch(() => toast.error("Something went wrong."))
        .finally(() => setLoadingLeaderboard(false));
    };

    if (group.id && contestsFilterInitialized) {
      fetchLeaderboard();
    } else {
      return;
    }
  }, [group.id, contestFilter?.id, phaseFilter?.id, contestsFilterInitialized, auth.signedIn]);

  const handleSetContestFilter = (contest: Contest | undefined) => {
    setContestFilter(contest);
    dispatch(setSelectedContest(contest));
  };

  useEffect(() => {
    Mixpanel.track("Leaderboard rendered", {});
  });

  const sortContestsByMostRecentJoined = (contests: Contest[], joinedContests: JoinedContest[]) => {
    const mostRecentContestEntryByContestId = new Map(
      joinedContests
        .filter(jc => jc.entries.length > 0)
        .map(jc => {
          const contestMostRecentEntry =
            jc.entries.sort((a, b) => Date.parse(b.created_at) - Date.parse(a.created_at))[0];
          return [jc.contest_id, contestMostRecentEntry];
        })
    );

    return contests.sort((c1, c2) => {
      const entryA = mostRecentContestEntryByContestId.get(c1.id)?.created_at;
      const entryB = mostRecentContestEntryByContestId.get(c2.id)?.created_at;

      if (!entryA && !entryB) return 0;
      if (!entryA) return 1;
      if (!entryB) return -1;

      return Date.parse(entryB) - Date.parse(entryA);
    });
  };

  return (
    <FullScreen handle={handle} className={styles.fullScreen}>
      {fullScreenActive && <FullscreenHeader />}
      <div>
        <div style={{ backgroundColor: primaryColor }}>
          <div className={styles.titleRow}>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              <BlockyHeavyText
                customStyles={{
                  fontSize: 22,
                  display: "flex",
                  alignItems: "center",
                  color: textColor,
                }}
              >
                Leaderboard
              </BlockyHeavyText>

              {fullScreenActive &&
                [contestFilter?.name, phaseFilter?.name]
                  .filter((string) => string !== undefined)
                  .map((filterLabel) => {
                    return (
                      <BlockyHeavyText
                        key={filterLabel}
                        customStyles={{
                          fontSize: 22,
                          display: "flex",
                          alignItems: "center",
                          color: textColor,
                        }}
                      >
                        {" "}
                        &nbsp;-&nbsp;{filterLabel}
                      </BlockyHeavyText>
                    );
                  })}
            </div>

            {!fullScreenActive && (
              <div style={{ display: "flex" }}>
                {contestFilter && (
                  <>
                    <div style={{ marginLeft: 5 }} />
                    {
                      group.isContestGroup && <Button
                        style={{
                          backgroundColor: "var(--sparket-black)",
                          border: "none",
                          color: "black",
                          background: secondaryColor,
                        }}
                        onClick={() => navigate(`/contest/${contestFilter?.id}`)}
                      >
                        <div className={"d-flex flex-row gap-1 align-items-center"}><Trophy />
                          <BlockyBoldText>View Contest</BlockyBoldText>
                        </div>
                      </Button>
                    }

                    <div style={{ marginLeft: 5 }} />
                    <Button
                      style={{
                        backgroundColor: "var(--sparket-black)",
                        border: "none",
                        color: "white",
                      }}
                      onClick={() => dispatch(openContestTerms())}
                    >
                      <GiftFill size={16} color={secondaryColor} style={{ marginTop: 4, marginBottom: 4 }} /> Prizes
                    </Button>
                  </>
                )}
                {!isMobile && (
                  <Button
                    style={{
                      marginLeft: 5,
                      backgroundColor: "var(--sparket-black)",
                      border: "none",
                      color: "white",
                    }}
                    onClick={enterFullScreen}
                  >
                    <Fullscreen size={12} /> Fullscreen
                  </Button>
                )}
              </div>
            )}
          </div>

          {contestsFilterInitialized && availableContests &&
            <div style={{ display: fullScreenActive ? "none" : "initial" }}>
              <hr style={{ marginTop: 0, marginBottom: 0 }} />
              <div
                style={{ backgroundColor: primaryColor }}
                className={styles.filterRow}
              >
                <ContestAndPhaseSelector
                  contests={availableContests}
                  selectedContest={contestFilter}
                  setContest={contest => handleSetContestFilter(contest)}
                  selectedPhase={phaseFilter}
                  setPhase={setPhaseFilter}
                  disabled={loadingLeaderboard || !contestsFilterInitialized}
                />
              </div>
            </div>
          }
        </div>
        <>
          {!availableContests || loadingLeaderboard || !leaderboardResponse ?
            <LoadingSpinner /> :
            <>
              {leaderboardResponse.user_list ?
                <>
                  <LeaderTable
                    fullScreen={fullScreenActive}
                    myEntries={leaderboardResponse.user_entries ?? []}
                    entries={leaderboardResponse.user_list ?? []}
                    disableUserPageLink={!userHasJoinedTheGroup || !group.isContestGroup || !contestFilter || !auth.signedIn}
                  />
                  <div className={styles.totalContestants}>
                    <BlockyBoldText>Out of </BlockyBoldText>
                    <BlockyHeavyText
                      style={{
                        marginLeft: 3,
                        marginRight: 3,
                        color: primaryColor,
                      }}
                    >
                      {leaderboardResponse.total_contestants}
                    </BlockyHeavyText>
                    <BlockyBoldText> entries</BlockyBoldText>
                  </div>
                </> :
                <BlockyHeavyText
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 15,
                  }}
                >
                  No results yet, please check back later.
                </BlockyHeavyText>}
            </>}
        </>
      </div>
      {fullScreenActive && <CallToActionFooter />}
    </FullScreen>
  );
};

export default Leaderboard;
