import { BackButton } from "components/contests/contest-display-items/shared/buttons";
import BalanceHeader from "../header/balance-header";
import { ReactComponent as MyPicksIcon } from "icons/MYPICKS.svg";
import React from "react";
import { sparketBlack } from "utils/constants";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import styles from "components/contests/contest-display-items/shared/styles.module.css";
import { toggle as toggleMyPickState } from "state/slices/my-picks-popout-slice.ts";
import { useAppDispatch } from "state/hooks.ts";

interface Props {
  contestEntryId?: string;
}

const MyContestBanner = ({ contestEntryId }: Props) => {

  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        backgroundColor: sparketBlack,
        height: 61,
      }}
    >
      <div style={{ position: "absolute", left: 0 }}>
        <BackButton backUrl={"/"} />
      </div>

      {!!contestEntryId &&
        <>
          <BalanceHeader contestEntryId={contestEntryId} />

          <div
            onClick={() => {
              dispatch(toggleMyPickState());
            }}
            className={styles.prizeContainer}
            style={{
              position: "absolute",
              right: 20
            }}>
            <BlockyHeavyText
              className={styles.bottomContainer}
              style={{
                marginBottom: 6
              }}
            >
              <u><i>My Picks</i></u>
            </BlockyHeavyText>
            <div className={"d-flex align-items-center justify-content-center"}>
              <MyPicksIcon fill={"white"} height={17} width={17} />
            </div>

          </div>
        </>
      }
    </div>
  );
};

export default MyContestBanner;