import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";
import { Button } from "react-bootstrap";
import { secondaryColor } from "utils/constants.ts";

interface Props {
  onClick: () => void;
}

const Container = ({ onClick }: Props) => {
  const buttonStyles = {
    background: secondaryColor,
    color: "black",
    border: secondaryColor,
    width: "100%",
  };

  return (
    <>
      <Button
        style={buttonStyles}
        onClick={onClick}
      >
        <BlockyBoldText style={{fontSize: 16}}>Enter Contest</BlockyBoldText>
      </Button>
    </>
  );
};

export default Container;
