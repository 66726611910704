import { Nav, Tab } from "react-bootstrap";
import { Entry } from "interfaces/entry";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import SingleSelectionEntryList
  from "components/pool-details/entries/single-selection-entry-list/single-selection-entry-list";
import OrderedSelectionEntryList
  from "components/pool-details/entries/ordered-selection-entry-list/ordered-selection-entry-list";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { useCallback, useEffect, useState } from "react";
import BetForm from "./bet-form";
import { Bet } from "interfaces/bet";
import { useAuth } from "hooks/auth";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { getGroupState, requestToJoinGroup } from "state/slices/group-slice";
import { SKIP, XPOINT_NOT_FOUND } from "utils/constants";
import JoinGroupModal from "home/join-group-button/join-group-modal";
import { showSignIn } from "state/slices/login-modals-slice";
import useGeolocation from "hooks/useGeolocation";
import GeolocationStatus from "components/pool-details/geolocation/status-badge";
import GeolocationModal from "components/pool-details/geolocation/modal/modal";
import LoadingBetForm from "./loading-bet-form";
import { betTypeWin } from "interfaces/bet-type-name";
import { sortBetTypes } from "components/pool-details/bet-types";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import { postAttemptToPlaceABetMessage } from "utils/integration/iframe-messages";
import { CheckRequestStatus } from "@xpointtech/xpoint-js";
import ContestModal from "components/contests/contest-modal";
import { Contest } from "interfaces/leaderboard/contest.ts";

interface EntryListProps {
  pool: PoolDetailsResponse;
  setCurrentPoolType: any;
  onSuccessfulBetPlaced: (bet: Bet) => void;
  contestEntryId?: string;
  contest?: Contest;
}

const EntryList = ({
  pool,
  setCurrentPoolType,
  onSuccessfulBetPlaced,
  contestEntryId,
  contest,
}: EntryListProps) => {
  const auth = useAuth();
  const [selectedEntry, setSelectedEntry] = useState<Entry>();
  const group = useAppSelector(getGroupState);
  const integratedAppState = useAppSelector(getIntegratedAppState);
  const dispatch = useAppDispatch();
  const userHasJoinedGroup = group.status === UserGroupMembershipStatus.APPROVED;
  const [showJoinGroupModal, setShowJoinGroupModal] = useState(false);
  const { geolocationStatus, checkStatus } = useGeolocation();
  const [showGeolocationModal, setShowGeolocationModal] = useState<CheckRequestStatus | typeof XPOINT_NOT_FOUND>(false);
  const [isFetchingEntry, setIsFetchingEntry] = useState(false);
  const [showJoinContestModal, setShowJoinContestModal] = useState(false);

  const allowGeolocationBadge =
    group.settings.enable_geolocation && auth.signedIn && userHasJoinedGroup;

  const orderedBetTypes = sortBetTypes(pool.pool_types);

  useEffect(() => {
    if (geolocationStatus === CheckRequestStatus.ALLOWED) {
      setShowGeolocationModal(false);
    }
  }, [geolocationStatus]);

  const hasNotJoinedContest = group.isContestGroup && !contestEntryId;

  const selectBet = useCallback(async (entry: Entry) => {
    if (!entry) {
      // User unselected a contestant, hide the form
      setSelectedEntry(undefined);
      return;
    }
    const geoResult = await checkStatus();

    setShowGeolocationModal(true);
    if (userHasJoinedGroup) {
      if (![CheckRequestStatus.ALLOWED, SKIP].includes(geoResult.status)) {
        setShowGeolocationModal(true);
      }
    }

    if (integratedAppState.isIntegratedApp) {
      postAttemptToPlaceABetMessage(pool.id);

      if (integratedAppState.userId) {
        setSelectedEntry(entry);
        setIsFetchingEntry(false);
      }
      return;
    }

    if (!auth.signedIn) {
      dispatch(showSignIn());
      return;
    }

    if (group.name && !userHasJoinedGroup) {
      setShowJoinGroupModal(true);
      return;
    }

    if (hasNotJoinedContest) {
      setShowJoinContestModal(true);
      return;
    }

    setSelectedEntry(entry);
    setIsFetchingEntry(false);
  }, [dispatch, auth.signedIn, group.name, userHasJoinedGroup, pool.id, checkStatus, integratedAppState.isIntegratedApp, integratedAppState.userId]);

  const memoizedSetIsFetchingEntry = useCallback(() => {
    // calculate if we should show loading state or not
    // const geoResult = await checkStatus();

    if (userHasJoinedGroup && auth.signedIn) {
      setIsFetchingEntry(true);
    } else {
      setIsFetchingEntry(false);
    }
  }, [userHasJoinedGroup, auth.signedIn]);

  return (
    <div>
      {allowGeolocationBadge && (
        <GeolocationStatus
          status={geolocationStatus}
          showGeolocationModal={setShowGeolocationModal}
        />
      )}
      <Tab.Container
        id="bet-type-tabs"
        defaultActiveKey={orderedBetTypes[0]}
        onSelect={(tab) => {
          setCurrentPoolType(tab);
          setSelectedEntry(undefined);
        }}
      >
        {orderedBetTypes.length > 1 && (
          <Nav
            justify
            variant="pills"
            style={{
              paddingTop: 10,
              paddingBottom: 5,
              paddingLeft: 20,
              paddingRight: 20,
            }}
          >
            {orderedBetTypes.length > 1 &&
              orderedBetTypes.map((type) => {
                return (
                  <Nav.Item key={type} style={{ paddingRight: 5 }}>
                    <Nav.Link eventKey={type} style={{ textTransform: "capitalize", cursor: "pointer" }}>
                      <BlockyBoldText>{type}</BlockyBoldText>
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
          </Nav>
        )}{" "}
        <Tab.Content style={{ position: "relative" }}>
          {orderedBetTypes.map((type) => {
            if (type === betTypeWin) {
              return (
                <Tab.Pane key={type} eventKey={type}>
                  <SingleSelectionEntryList
                    contestants={pool.contestants}
                    entries={pool.entries}
                    selectBet={selectBet}
                    winningContestantIds={pool.winner_contestant_ids_places ? (pool.winner_contestant_ids_places[1] || []) : []}
                    poolStatus={pool.status}
                    selectedEntry={selectedEntry}
                  />
                </Tab.Pane>
              );
            } else {
              return (
                <Tab.Pane key={type} eventKey={type}>
                  <OrderedSelectionEntryList
                    contestants={pool.contestants}
                    selectBet={selectBet}
                    poolType={type}
                    poolId={pool.id}
                    pool={pool}
                    setIsFetchingEntry={memoizedSetIsFetchingEntry}
                  />
                </Tab.Pane>
              );
            }
          })}
        </Tab.Content>
        {selectedEntry && (
          <div style={{ height: 163 }}>
          </div>
        )}
      </Tab.Container>
      {selectedEntry && (
        <BetForm
          entry={selectedEntry}
          pool={pool}
          onSuccessfulBetPlaced={onSuccessfulBetPlaced}
          contestEntryId={contestEntryId}
        />
      )}
      {isFetchingEntry && <LoadingBetForm />}
      <JoinGroupModal
        acceptAndJoin={() => {
          dispatch(requestToJoinGroup(group.id));
          setShowJoinGroupModal(false);
          if (hasNotJoinedContest) {
            setShowJoinContestModal(true);
          }
        }}
        show={showJoinGroupModal}
        close={() => setShowJoinGroupModal(false)}
      />
      {group.settings.enable_geolocation && auth.signedIn && (
        <GeolocationModal
          show={showGeolocationModal && group.settings.enable_geolocation}
          hide={() => {
            setShowGeolocationModal(false);
            checkStatus();
          }}
        />
      )}
      {group.isContestGroup && contest &&
        <ContestModal
          contest={contest}
          show={showJoinContestModal}
          setShow={setShowJoinContestModal}
        />
      }
    </div>
  );
};

export default EntryList;
