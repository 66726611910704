import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import { useAppSelector } from "state/hooks";
import { getGroupCurrencySymbol, getGroupState } from "state/slices/group-slice";
import { useCallback, useEffect, useState } from "react";
import { getRequest, postRequest } from "utils/httpClient";
import { useAuth } from "./auth";
import { getBalancePath, getWalletPath } from "utils/backend-path-builders";
import { getIntegratedAppState } from "state/slices/integrated-app-slice";
import { UserGroupMembershipStatus } from "utils/userGroupMembershipStatus";
import store from "state/store";
import { postBalanceMessage } from "utils/integration/iframe-messages";

interface WalletProps {
  contestEntryId?: string;
}

const useWallet = ({ contestEntryId }: WalletProps = {}) => {
  const auth = useAuth();
  const [balance, setBalance] = useState<number | undefined>();
  const [walletId, setWalletId] = useState("");
  const [loading, setLoading] = useState(false);
  const group = useAppSelector(getGroupState);
  const groupId = group.id;
  const groupStatus = group.status;
  const integratedAppState = useAppSelector(getIntegratedAppState);
  const state = store.getState();
  const groupSymbol = getGroupCurrencySymbol(state);

  const getWalletData = useCallback(() => {
    if (integratedAppState.isIntegratedApp) {
      if (integratedAppState.userId) {
        setLoading(true);
        postRequest(getBalancePath())
          .then((response) => {
            postBalanceMessage(response.total);
            setBalance(response.total);
            setWalletId(response.wallet_id);
          })
          .catch((e) => console.log("failed to fetch balance", e))
          .finally(() => setLoading(false));
      }
    } else if (
      (auth.signedIn && groupId !== "" && groupStatus === UserGroupMembershipStatus.APPROVED) || contestEntryId
    ) {
      const walletPath = contestEntryId
        ? `${getWalletPath(groupId)}?contestEntryId=${contestEntryId}`
        : getWalletPath(groupId);

      setLoading(true);
      getRequest(walletPath)
        .then((data) => {
          setBalance(data.current_balance || 0);
          setWalletId(data.wallet_id);
        })
        .catch((e) => console.log("failed to fetch balance", e))
        .finally(() => setLoading(false));
    }
  }, [
    auth.signedIn,
    groupId,
    groupStatus,
    contestEntryId,
    integratedAppState.userId,
    integratedAppState.isIntegratedApp,
  ]);

  useEffect(() => {
    getWalletData();
  }, [getWalletData]);

  let formattedBalance = groupSymbol + " --.--";
  if (balance !== undefined) {
    formattedBalance = formatAsCurrency(balance!);
  }

  return { balance, walletId, loading, formattedBalance, getWalletData };
};

export default useWallet;
