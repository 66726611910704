import useContestData from "hooks/useContestData.ts";
import styles from "components/my-bets/styles.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text.tsx";
import React from "react";
import { JoinedContest } from "interfaces/leaderboard/contest.ts";
import { JoinedContestItem } from "components/contests/contest-display-items";

const MyContests = () => {
  const { joinedContests } = useContestData();

  const hasContests = joinedContests.length > 0;

  if (!hasContests) {
    return (
      <div className={styles.noBetsTextContainer}>
        <BlockyHeavyText>You haven't joined any contests yet</BlockyHeavyText>
      </div>
    )
  }

  return (
    <>
      {joinedContests.map((joinedContest: JoinedContest) => (
        <JoinedContestItem key={joinedContest.contest_id} joinedContest={joinedContest} />
      ))}
    </>
  )
}

export default MyContests;