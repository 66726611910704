import { Checkmark } from "react-checkmark";
import BlockyBoldText from "components/custom-texts/blocky-bold-text.tsx";

const EnteredCheck = () => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        color: "var(--sparket-green)",
        fontSize: 12,
      }}
    >
      <Checkmark size="small" /> &nbsp;
      <BlockyBoldText>Entered</BlockyBoldText>
    </div>
  )
}

export default EnteredCheck;